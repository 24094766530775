<template>
    <div class="image-field">
        <div
            class="image-field__label"
            v-if="label && (imageUrl || imageFileName || alwaysShowLabel)"
        >
            {{ label }}
        </div>
        <div
            v-if="imageUrl && !imageFileName"
            class="image-field__image"
            :class="{'image-field__image--compact': compact }"
            @click="openImage"
        >
            <img :src="imageUrl">
        </div>
        <div
            v-else-if="imageFileName"
            class="image-field__file-name"
            :class="{ 'image-field__file-name--compact': compact }"
        >
            {{ imageFileName }}
        </div>
        <div
            v-if="imageFileName || imageUrl"
            class="image-field__remove-btn"
            :class="{
                'image-field__remove-btn--compact': compact,
                'image-field__remove-btn--disabled': disabled,
            }"
            @mouseenter="!disabled && (showRemoveTooltip = true)"
            @mouseleave="!disabled && (showRemoveTooltip = false)"
            @click="!disabled && removeImage()"
        >
            <Tooltip v-if="showRemoveTooltip">
                {{ tooltip }}
            </Tooltip>
            <Icon type="close" class="question-draft-form__passage-close-icon"/>
        </div>
        <div
            v-else-if="!imageUrl && !imageFileName"
            :style="{ position: 'relative' }"
        >
            <label
                class="image-field__styled-upload-button"
                :class="{
                    'image-field__styled-upload-button--disabled': disabled,
                }"
            >
                <span> {{ buttonName }} </span>
                <input
                    class="image-field__hidden-upload-btn"
                    type="file"
                    :disabled="disabled"
                    @change="valueChange"
                >
            </label>
        </div>
        <div v-if="imageFileName || imageUrl">
            <label
                class="image-field__replace-button"
                :class="{
                    'image-field__replace-button--compact': compact,
                    'image-field__replace-button--disabled': disabled,
                }"
            >
                <span>Replace File</span>
                <input
                    class="image-field__hidden-upload-btn"
                    type="file"
                    :disabled="disabled"
                    @change="valueChange"
                >
            </label>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'

@Component({
    components: {
        Icon: UIKit.Icon,
        Tooltip: UIKit.Tooltip,
    },
})
export default class ImageField extends Vue {
    @Prop() readonly image?: string
    @Prop() readonly label?: string
    @Prop({ default: false }) readonly disabled!: boolean
    @Prop() readonly compact?: boolean
    @Prop() readonly alwaysShowLabel?: boolean
    @Prop({ default: 'Choose File' }) readonly buttonName?: string
    @Prop({ default: 'Remove Image' }) readonly tooltip?: string

    imageUrl: string | null = null
    imageFileName: string | null = null
    showRemoveTooltip = false

    created () {
        this.imageUrl = this.image || null
    }

    openImage () {
        if (this.imageUrl) {
            window.open(this.imageUrl, '_blank')
        }
    }

    removeImage () {
        this.imageUrl = null
        this.imageFileName = null
        this.showRemoveTooltip = false

        this.emitRemoveImage()
    }

    @Watch('image')
    imageUrlChanged (val: string) {
        this.imageUrl = val
    }

    @Emit('update:modelValue')
    valueChange ($event: Event) {
        const files = ($event.target as HTMLInputElement).files
        const file = files && files.length && files[0]

        if (!(file instanceof File)) {
            this.emitError('Invalid file. Selected file isn\'t recognized as a file.')
            return null
        }

        this.imageFileName = file.name || null
        return file
    }

    @Emit('remove')
    emitRemoveImage () {
        return true
    }

    @Emit('error')
    emitError (error: string) {
        return error
    }
}
</script>

<style lang="scss" scoped>

.image-field {
    position: relative;
    min-height: 41px;

    &__image {
        cursor: pointer;

        img {
            position: relative;
            max-height: 200px;
            max-width: 396px;
        }

        &--compact {
            img {
                max-height: 200px;
                max-width: 221px;
            }
        }
    }

    &__label {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        margin: 18px 0 4px 12px;
        color: $pickled-bluewood;
    }

    &__file-name {
        position: absolute;
        font-size: 15px;
        font-weight: 600;
        padding-left: 12px;

        &--compact {
            width: 221px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 20px;
        }
    }

    &__styled-upload-button, &__replace-button {
        padding: 10px 18px 9px 18px;
        border: 1px solid $brand-blue;
        border-radius: 4px;
        color: $brand-blue;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        white-space: nowrap;

        &--disabled {
            opacity: 0.35;
        }

        &:not(&--disabled):hover {
            cursor: pointer;
            background-color: $brand-blue;
            color: $white;
        }
    }
    
    &__replace-button {
        left: 466px;
        top: 10px;

        &--compact {
            left: 274px;
        }
    }

    &__remove-btn {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 420px;
        color: $brand-blue;
        top: 20px;

        &--disabled {
            opacity: 0.35;
        }

        &--compact {
            left: 231px;
        }

        .uikit-tooltip {
            position: absolute;
            top: -37px;
            left: 10px;
        }

        &:not(&--disabled):hover { 
            cursor: pointer;
            
            svg {
                color: $brand-black;
            }
        }
    }

    &__hidden-upload-btn {
        display: none;
    }
}
</style>
