import type { INavigationHistory, INavigationModuleState } from './types'

export const getInitialNavigationState = (): INavigationModuleState => {
    const menuVisibleString = localStorage.getItem('menuVisible')
    const menuVisible: boolean = menuVisibleString && JSON.parse(menuVisibleString) || false
    const navigationHistory: INavigationHistory[] = []

    return {
        menuVisible,
        navigationHistory,
    }
}

const moduleState = getInitialNavigationState()

export default moduleState
