<template>
    <div class="question-drafts">
        <Loading v-if="isLoading" />
        <div
            class="question-drafts__title"
        >
            <TitleText>
                Question Drafts
            </TitleText>
        </div>
        <List
            v-if="!isLoading"
            store-name="questionDraftsList"
            :list-options="listOptions"
            :show-last-page-btn="true"
            :force-show-controls="true"
            :show-case-sensitive-toggle="true"
            list-unit="Question Draft"
            @itemClicked="questionDraftClicked"
        >
            <template #filterTypeDropdownListItem="{ item }">
                <template v-if="item.label === 'Serial'">
                    <div class="question-drafts__filter-type-dropdown-item-container">
                        <div class="question-drafts__filter-type-dropdown-section-title">
                            TABLE COLUMN
                        </div>
                        <div class="question-drafts__filter-type-dropdown-item">
                            {{ item.label }}
                        </div>
                    </div>
                </template>
                <template v-if="item.label === 'Bloom\'s'">
                    <div class="question-drafts__filter-type-dropdown-item-container">
                        <div class="question-drafts__filter-type-dropdown-section-title">
                            QUESTION FIELDS
                        </div>
                        <div class="question-drafts__filter-type-dropdown-item">
                            {{ item.label }}
                        </div>
                    </div>
                </template>
                <template v-if="item.label === 'New question'">
                    <div class="question-drafts__filter-type-dropdown-item-container">
                        <div class="question-drafts__filter-type-dropdown-section-title">
                            T/F FILTERS
                        </div>
                        <div class="question-drafts__filter-type-dropdown-item">
                            {{ item.label }}
                        </div>
                    </div>
                </template>
            </template>
            <template #filterTextDropdownListItem="{ item, field }">
                <template v-if="field.propName === 'type'">
                    <div
                        v-if="item.label === 'MC'"
                        class="question-drafts__question-type-dropdown-item"
                    >
                        <div class="question-drafts__question-type-dropdown-item-label">
                            MC
                        </div>
                        <div>
                            Multiple Choice Question
                        </div>
                    </div>
                    <div
                        v-else-if="item.label === 'MCR'"
                        class="question-drafts__question-type-dropdown-item"
                    >
                        <div class="question-drafts__question-type-dropdown-item-label">
                            MCR
                        </div>
                        <div>
                            Multiple Correct Response
                        </div>
                    </div>
                    <div
                        v-else-if="item.label === 'mCB'"
                        class="question-drafts__question-type-dropdown-item"
                    >
                        <div class="question-drafts__question-type-dropdown-item-label">
                            mCB
                        </div>
                        <div>
                            Matrix Checkbox
                        </div>
                    </div>
                    <div
                        v-else-if="item.label === 'mRB'"
                        class="question-drafts__question-type-dropdown-item"
                    >
                        <div class="question-drafts__question-type-dropdown-item-label">
                            mRB
                        </div>
                        <div>
                            Matrix Radio Button
                        </div>
                    </div>
                    <div
                        v-if="item.label === 'TF'"
                        class="question-drafts__question-type-dropdown-item"
                    >
                        <div class="question-drafts__question-type-dropdown-item-label">
                            TF
                        </div>
                        <div>
                            True/False
                        </div>
                    </div>
                </template>
            </template>
            <template #tableColumnLabelText="{ column }">
                <template v-if="column.propName === 'type'">Type</template>
                <template v-else-if="column.propName === 'prompt'">Question Prompt</template>
            </template>
        </List>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { List } from '@/components/Lists'
import type {
    IListOptions, 
    IFilterSortParams, 
    IFilterSortReturn, 
    IProcessedTextField, 
    IProcessedDateField, 
} from '@/components/Lists'
import Loading from '@/components/Loading.vue'
import type { CMS, Study } from '@pocketprep/types'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import type { IParseQuestionDraft, IQuestionDraft, TSearchQDraftParams } from '@/store/questionDrafts/types'
import questionDraftsModule from '@/store/questionDrafts/module'
import TitleText from '@/components/TitleText.vue'
import { bloomTaxonomyLevels } from '@/utils'

interface IMappedQuestionDraft {
    objectId: string
    serial?: string
    appName?: string
    prompt: string
    explanation: string
    passage: string
    references: string
    knowledgeAreaDraft?: string
    type: 'MC' | 'MCR' | 'mCB' | 'mRB' | 'TF'
    jobStatus?: string
    isArchived: 'Yes' | 'No'
    hasComments: 'Yes' | 'No'
    hasSuggestions: 'Yes' | 'No'
    isFlagged: 'Yes' | 'No'
    isNewQuestion: 'Yes' | 'No'
    isSpecial: 'Yes' | 'No'
    willResetMetrics: boolean
    isMockQuestion: 'Yes' | 'No'
    subtopic: string
    bloomTaxonomyLevel: Study.Class.BloomTaxonomyLevel
    bank: string
    record: 'Update' | 'New'
    passageImage: 'Yes' | 'No'
    explanationImage: 'Yes' | 'No'
}

type TEqualToFilter = IProcessedTextField<IMappedQuestionDraft> | IProcessedDateField<IMappedQuestionDraft>

@Component({
    components: {
        List,
        Loading,
        TitleText,
    },
})
export default class QuestionDraftList extends Vue {
    knowledgeAreaDrafts: CMS.Class.KnowledgeAreaDraftJSON[] = []
    isLoading = true

    async mounted () {
        // Fetch all the knowledge areas so that we can map their names onto the question drafts
        // This is faster than doing a KnowledgeAreaDraft query for each question draft's KnowledgeAreaDraft pointer
        this.isLoading = true
        this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADrafts()
        this.isLoading = false
    }

    createOrderByProp (
        filterSortParams: IFilterSortParams<IMappedQuestionDraft>
    ): TSearchQDraftParams['orderBy'] {
        const sortBy = filterSortParams.sort.by
        if (sortBy === 'isNewQuestion') {
            return 'examDataId'
        } else if (sortBy === 'subtopic') {
            return 'subtopicId'
        } else if (
            sortBy === 'bank' || 
            sortBy === 'record' ||
            sortBy === 'passageImage' ||
            sortBy === 'explanationImage'
        ) {
            return
        } else {
            return sortBy
        }
    }

    createExistsFilter (
        filterSortParams: IFilterSortParams<IMappedQuestionDraft>
    ): NonNullable<TSearchQDraftParams['exists']> {
        return filterSortParams.filters
            .reduce((acc: NonNullable<TSearchQDraftParams['exists']>, { filter, propName }) => {
                if (filter && filter.state) {
                    if (propName === 'passageImage') {
                        acc.push('images.passage' as 'images')
                    }
                    if (propName === 'explanationImage') {
                        acc.push('images.explanation' as 'images')   
                    }
                    if (propName === 'isNewQuestion') {
                        acc.push('examDataId')   
                    }
                }
                return acc
            }, [])
    }

    async getMappedQuestionDrafts (
        filterSortParams: IFilterSortParams<IMappedQuestionDraft>
    ): Promise<IFilterSortReturn<IMappedQuestionDraft>> {
        // Build the 'contains' object for the search query based on the current list filters
        const contains = filterSortParams.filters
            .filter(filter => !('data' in filter) || !filter.data)
            .reduce((acc: NonNullable<TSearchQDraftParams['contains']>, { type, filter, propName }) => {
                const accUpdate = acc
                if (filter && type === 'text' && typeof filter.state === 'string' && filter.state && propName) {
                    if (propName === 'type') {
                        accUpdate[propName] = filter.state === 'MC'
                            ? 'Multiple Choice'
                            : filter.state === 'MCR'
                                ? 'Multiple Correct Response' 
                                : filter.state === 'mCB'
                                    ? 'Matrix Checkbox'
                                    : filter.state === 'mRB'
                                        ? 'Matrix Radio Button'
                                        : 'True/False'
                    } else if (
                        propName !== 'isNewQuestion'
                        && propName !== 'knowledgeAreaDraft' 
                        && propName !== 'subtopic' 
                        && propName !== 'bank'
                        && propName !== 'record'
                        && propName !== 'passageImage'
                        && propName !== 'explanationImage'
                    ) {
                        accUpdate[propName] = filter.state
                    }
                }
                return accUpdate
            }, {})

        const equalTo = filterSortParams.filters
            .filter((filter): filter is TEqualToFilter => ('data' in filter) && !!filter.data)
            .reduce((acc: NonNullable<TSearchQDraftParams['equalTo']>, { type, filter, propName, data }) => {
                const accUpdate = acc
                if (
                    filter && 
                    type === 'text' && 
                    typeof filter.state === 'string' && 
                    filter.state && 
                    propName && 
                    data
                ) {
                    if (
                        propName === 'hasSuggestions'
                        || propName === 'hasComments'
                        || propName === 'isArchived'
                        || propName === 'isSpecial'
                        || propName === 'isFlagged'
                        || propName === 'isMockQuestion'
                        
                    ) {
                        accUpdate[propName] = filter.state === 'Yes' ? true : false
                    } else if (propName === 'type') {
                        accUpdate[propName] = filter.state === 'MC'
                            ? 'Multiple Choice'
                            : filter.state === 'MCR'
                                ? 'Multiple Correct Response' 
                                : filter.state === 'mCB'
                                    ? 'Matrix Checkbox'
                                    : filter.state === 'mRB'
                                        ? 'Matrix Radio Button'
                                        : 'True/False'
                    } else if (propName === 'jobStatus' || propName === 'bloomTaxonomyLevel') {
                        accUpdate[propName] = filter.state
                    } else if (propName === 'knowledgeAreaDraft') {
                        const filterState = filter.state
                        const matchingKA = this.knowledgeAreaDrafts.find(ka => ka.name === filterState)
                        if (matchingKA) {
                            accUpdate[propName] = {
                                __type: 'Pointer' as const,
                                className: 'KnowledgeAreaDraft',
                                objectId: matchingKA.objectId || '',
                            }
                        }
                    } else if (propName === 'subtopic') {
                        const filterState = filter.state
                        let matchingSubtopicId: string | undefined
                        this.knowledgeAreaDrafts.find(ka => {
                            return ka.subtopics?.some(sub => {
                                if (sub.name === filterState) {
                                    matchingSubtopicId = sub.id
                                    return true
                                } else {
                                    return false
                                }
                            })
                        })
                        if (matchingSubtopicId) {
                            accUpdate['subtopicId'] = matchingSubtopicId
                        } else {
                            accUpdate['subtopicId'] = filterState
                        }
                    }
                }
                return accUpdate
            }, {})

        const doesNotExist: (keyof IParseQuestionDraft)[] = filterSortParams.filters
            .filter(({ filter, propName }) => propName === 'isNewQuestion' && filter && filter.state === 'Yes')
            .length ? [ 'examDataId' ] : []

        // Create the searchParams, including pagination, sorting, and filtering
        const searchParams: TSearchQDraftParams = {
            perPage: filterSortParams.pagination.perPage,
            page: filterSortParams.pagination.page,
            orderBy: this.createOrderByProp(filterSortParams),
            order: filterSortParams.sort.direction === 'DESC' ? 'descending' : 'ascending',
            contains,
            equalTo,
            exists: this.createExistsFilter(filterSortParams),
            doesNotExist,
            searchAll: {
                keys: [
                    'knowledgeAreaDraft',
                    'serial',
                    'appName',
                    'type',
                    'prompt',
                    'references',
                    'passage',
                    'explanation',
                ],
                value: filterSortParams.searchAll,
            },
            caseSensitive: filterSortParams.caseSensitive,
        }

        const questionDraftsResponse = await questionDraftsModule.actions.fetchQuestionDrafts(searchParams)

        return {
            results: questionDraftsResponse.results.map(
                ({ 
                    objectId,
                    serial,
                    appName,
                    prompt, 
                    knowledgeAreaDraft, 
                    type, 
                    jobStatus, 
                    isArchived, 
                    hasSuggestions, 
                    hasComments,
                    isFlagged,
                    examDataId,
                    isSpecial,
                    explanation,
                    references,
                    passage,
                    willResetMetrics,
                    isMockQuestion,
                    subtopicId,
                    bloomTaxonomyLevel,
                    images,
                }): IMappedQuestionDraft => {
                    const knowledgeAreaObj = this.knowledgeAreaDrafts
                        .find(ka => !!(knowledgeAreaDraft && ka.objectId === knowledgeAreaDraft.objectId))
                    const subtopicObj = subtopicId && knowledgeAreaObj?.subtopics?.find(sub => sub.id === subtopicId)
                    const explanationImage = images?.explanation
                    const passageImage = images?.passage
                    return {
                        objectId,
                        serial,
                        appName,
                        knowledgeAreaDraft: (knowledgeAreaObj && knowledgeAreaObj.name) || '',
                        explanation: explanation || '',
                        references: references && references.join(' ') || '',
                        passage: passage || '',
                        type: type === 'Multiple Choice' 
                            ? 'MC'
                            : type === 'Multiple Correct Response' 
                                ? 'MCR'
                                : type === 'Matrix Checkbox'
                                    ? 'mCB'
                                    : type === 'Matrix Radio Button'
                                        ? 'mRB'
                                        : 'TF',
                        jobStatus,
                        prompt: prompt || '',
                        isArchived: isArchived ? 'Yes' : 'No',
                        hasComments: hasComments ? 'Yes' : 'No',
                        hasSuggestions: hasSuggestions ? 'Yes' : 'No',
                        isFlagged: isFlagged ? 'Yes' : 'No',
                        isNewQuestion: examDataId ? 'No' : 'Yes',
                        isSpecial: isSpecial ? 'Yes' : 'No',
                        willResetMetrics: !!willResetMetrics,
                        isMockQuestion: isMockQuestion ? 'Yes' : 'No',
                        subtopic: subtopicObj ? subtopicObj.name : '',
                        bloomTaxonomyLevel: bloomTaxonomyLevel || 'None',
                        bank: isMockQuestion ? 'ME' : 'SQB',
                        record: examDataId ? 'Update' : 'New',
                        passageImage: passageImage ? 'Yes' : 'No',
                        explanationImage: explanationImage ? 'Yes' : 'No',
                    }
                }),
            totalCount: questionDraftsResponse.totalCount,
        }
    }

    get listOptions (): IListOptions<IMappedQuestionDraft> {
        return {
            listData: this.getMappedQuestionDrafts,
            listSchema: [
                {
                    propName: 'serial',
                    label: 'Serial',
                    type: 'text',
                    options: {
                        width: 122,
                        group: 0,
                    },
                },
                {
                    propName: 'appName',
                    label: 'Exam',
                    type: 'text',
                    options: {
                        width: 128,
                        group: 0,
                    },
                },
                {
                    propName: 'knowledgeAreaDraft',
                    label: 'Subject',
                    type: 'text',
                    options: {
                        width: 230,
                        group: 0,
                        sort: false,
                    },
                    data: this.knowledgeAreaDrafts.map(ka => ka.name),
                },
                {
                    propName: 'subtopic',
                    label: 'Subtopic',
                    type: 'text',
                    options: {
                        width: 124,
                        group: 0,
                        sort: false,
                    },
                    data: this.knowledgeAreaDrafts?.flatMap(ka => ka.subtopics?.map(sub => sub.name) || []),
                },
                {
                    propName: 'type',
                    label: 'Question type',
                    type: 'text',
                    options: {
                        width: 60,
                        group: 0,
                    },
                    data: [ 'MC', 'MCR', 'mCB', 'mRB', 'TF' ],
                },
                {
                    propName: 'prompt',
                    label: 'Question prompt',
                    type: 'text',
                    options: {
                        style: 'overflow-ellipsis',
                        group: 1,
                        width: 420,
                    },
                },
                {
                    propName: 'bloomTaxonomyLevel',
                    label: 'Bloom\'s',
                    type: 'text',
                    data: bloomTaxonomyLevels,
                    options: {
                        isHidden: true,
                        group: 0,
                    },
                },
                {
                    propName: 'bank',
                    label: 'Bank',
                    type: 'text',
                    options: {
                        group: 0,
                        width: 61,
                        sort: false,
                        filter: false,
                    },
                },
                {
                    propName: 'record',
                    label: 'Record',
                    type: 'text',
                    options: {
                        group: 0,
                        width: 83,
                        sort: false,
                        filter: false,
                    },
                },
                {
                    propName: 'passage',
                    label: 'Supplemental Info',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'explanation',
                    label: 'Explanation',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'references',
                    label: 'References',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                },
                {
                    propName: 'jobStatus',
                    label: 'Job Status',
                    type: 'text',
                    options: {
                        isHidden: true,
                        sort: false,
                        filter: false,
                    },
                    data: [ 'Writer', 'Editor', 'Completed' ],
                },
                {
                    propName: 'hasSuggestions',
                    label: 'Suggestions',
                    type: 'text',
                    options: {
                        isHidden: true,
                        sort: false,
                        filter: false,
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'hasComments',
                    label: 'Comments',
                    type: 'text',
                    options: {
                        isHidden: true,
                        sort: false,
                        filter: false,
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'isFlagged',
                    label: 'Flagged',
                    type: 'text',
                    options: {
                        isHidden: true,
                        sort: false,
                        filter: false,
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'isNewQuestion',
                    label: 'New question',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes', 'No' ],
                    prePopulateSelectValue: 'Yes',
                },
                {
                    propName: 'isSpecial',
                    label: 'Free question',
                    type: 'text',
                    options: {
                        isHidden: true,
                        sort: false,
                    },
                    data: [ 'Yes', 'No' ],
                    prePopulateSelectValue: 'Yes',
                },
                {
                    propName: 'isMockQuestion',
                    label: 'Mock exam question',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes', 'No' ],
                    prePopulateSelectValue: 'Yes',
                },
                {
                    propName: 'isArchived',
                    label: 'Archived question',
                    type: 'text',
                    options: {
                        isHidden: true,
                        filter: 'No',
                        defaultValue: 'No',
                    },
                    prePopulateSelectValue: 'Yes',
                    doNotDisplayPillValue: 'No',
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'passageImage',
                    label: 'Has question image',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes' ],
                    prePopulateSelectValue: 'Yes',
                },
                {
                    propName: 'explanationImage',
                    label: 'Has explanation image',
                    type: 'text',
                    options: {
                        isHidden: true,
                    },
                    data: [ 'Yes' ],
                    prePopulateSelectValue: 'Yes',
                },
            ],
            defaultSort: {
                propName: 'appName',
                sortDir: 'ASC',
            },
            listDataModifiers: [
                data => data.isArchived === 'Yes' && { opacity: '0.5' },
            ],
            listDataIcons: [
                data => {
                    if (data.jobStatus === 'Writer') {
                        return { 
                            iconName: 'pencil', 
                            label: 'Status: ' + data.jobStatus, 
                            styles: { color: '#fff', backgroundColor: '#ff731a' }, 
                        }
                    } else if (data.jobStatus === 'Editor') {
                        return { 
                            iconName: 'edit', 
                            label: 'Status: ' + data.jobStatus, 
                            styles: { color: '#fff', backgroundColor: '#e2e106' } }
                    } else if (data.jobStatus === 'Completed') {
                        return { 
                            iconName: 'check', 
                            label: 'Status: ' + data.jobStatus, 
                            styles: { color: '#fff', backgroundColor: '#92c73d' }, 
                        }
                    }
                    return false
                },
                data => data.isSpecial === 'Yes' && {
                    iconName: 'gift',
                    label: 'Special',
                    styles: {
                        color: 'white',
                        backgroundColor: 'darkgreen',
                        fontSize: '15px',
                    },
                },
                data => data.isArchived === 'Yes' && { 
                    iconName: 'archive', 
                    label: 'Archived',
                    styles: { color: '#fff', backgroundColor: '#475964' }, 
                },
                data => data.isFlagged === 'Yes' && { 
                    iconName: 'flag', 
                    label: 'Question Flagged',
                    styles: { color: '#fff', backgroundColor: '#e63854' }, 
                },
                data => data.hasSuggestions === 'Yes' && { 
                    iconName: 'commentPlus', 
                    label: 'Question has Suggestions',
                    styles: { color: '#fff', backgroundColor: '#821313' }, 
                },
                data => data.hasComments === 'Yes' && { 
                    iconName: 'commentDots', 
                    label: 'Question has Comments',
                    styles: { color: '#fff', backgroundColor: '#135e82' }, 
                },
                data => data.isNewQuestion === 'Yes' && { 
                    iconName: 'star', 
                    label: 'New Question',
                    styles: { color: '#fff', backgroundColor: '#609b03' }, 
                },
                data => data.willResetMetrics && {
                    iconName: 'broom',
                    label: 'Reset Metrics',
                    styles: {
                        color: 'rgb(255, 0, 0)',
                        backgroundColor: '#fff',
                        fontSize: '15px',
                    },
                },
                data => data.isMockQuestion === 'Yes' && {
                    iconName: 'fileAlt',
                    label: 'Mock Question',
                    styles: {
                        color: '#fff',
                        backgroundColor: '#609b03',
                        fontSize: '15px',
                    },
                },
            ],
        }
    }

    questionDraftClicked (questionDraft: IQuestionDraft) {
        this.$router.push({
            name: 'question-draft-edit',
            params: {
                questionDraftId: String(questionDraft.objectId),
            },
        })
    }
}
</script>

<style lang="scss" scoped>

.question-drafts {
    padding: $base;
    padding-bottom: 0;
    flex-grow: 1;
    margin-top: 52px;

    &__title {
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-top: 46px;
        margin-bottom: 14px;
    }

    &__filter-type-dropdown-section-title {
        position: absolute;
        top: -24px;
        color: $slate;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    &__filter-type-dropdown-item-container {
        position: relative;
    }

    &__question-type-dropdown-item {
        display: flex;
    }

    &__question-type-dropdown-item-label {
        font-weight: 600;
        margin-right: 4px;
    }

    :deep(.uikit-select__item[data-value="Serial"]),
    :deep(.uikit-select__item[data-value="Bloom's"]),
    :deep(.uikit-select__item[data-value="New question"]) {
        margin-top: 31px;
    }
}
</style>