import { Parse } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import type { TFetchMockExams } from '@/store/mockExams/types'

/**
 * Fetch all mock exams from app server for a specific exam
 *
 */
const fetchMockExams = async (examMetadataId?: Parameters<TFetchMockExams>[0]): ReturnType<TFetchMockExams> => {
    const parseMockExams = await Parse.Cloud.run<CMS.Cloud.fetchMockExams>('fetchMockExams', { examMetadataId })
    const mockExams = parseMockExams.map(mockExam => mockExam.toJSON())

    return mockExams
}

export default {
    fetchMockExams,
}
