<template>
    <div class="info-message">
        <div class="info-message__content">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'

@Component
export default class InfoMessage extends Vue {
    
}
</script>

<style lang="scss" scoped>
.info-message {
    padding: 11px 16px;
    background-color: $barely-blue;
    border: 1px solid rgba($brand-blue, 0.4);
    border-radius: 6px;

    &__content {
        font-size: 15px;
        line-height: 20px;
    }
}
</style>
