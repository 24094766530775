<template>
    <div class="add-existing-question-draft">
        <PocketSelect
            class="add-existing-question-draft__select"
            placeholder="Search by prompt, supplemental info, or Serial/ID"
            v-model="selectedQuestion"
            typeahead="true"
            show-typeahead-clear="true"
            :data="mappedQuestionDrafts"
        >
            <template #dropdownListHeader>
                <div class="add-existing-question-draft__dropdown-column-labels">
                    <div class="add-existing-question-draft__dropdown-column-serial">
                        Serial
                    </div>
                    <div class="add-existing-question-draft__dropdown-column-prompt">
                        Prompt
                    </div>
                    <div class="add-existing-question-draft__dropdown-column-passage">
                        Supplemental Info
                    </div>
                </div>
            </template>
            <template #dropdownListItem="{ item }">
                <div class="add-existing-question-draft__dropdown-row">
                    <div class="add-existing-question-draft__dropdown-column-serial">{{ item.serial }}</div>
                    <div class="add-existing-question-draft__dropdown-column-prompt">{{ item.prompt }}</div>
                    <div class="add-existing-question-draft__dropdown-column-passage">{{ item.passage }}</div>
                </div>
            </template>
        </PocketSelect>
        <div class="add-existing-question-draft__actions">
            <PocketButton
                class="add-existing-question-draft__cancel-btn"
                type="secondary"
                @click="cancel"
            >
                Cancel
            </PocketButton>
            <PocketButton
                class="add-existing-question-draft__add-btn"
                type="primary"
                :disabled="isAddBtnDisabled"
                @click="add"
            >
                Add
            </PocketButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'
import type { IParseQuestionDraft } from '@/store/questionDrafts/types'
import UIKit from '@pocketprep/ui-kit'
import { stripHtmlTags } from '@/utils'
import type { INewScenarioQuestionDraft } from '@/store/questionScenarioDrafts/types'

@Component({
    components: {
        PocketSelect: UIKit.Select,
        PocketButton: UIKit.Button,
    },
})
export default class AddExistingQuestionDraft extends Vue {
    @Prop() orderedQuestionDrafts: ((IParseQuestionDraft & { serial: string }) | INewScenarioQuestionDraft)[] = []
    @Prop({ default: [] }) jobQuestionDrafts!: (IParseQuestionDraft & { serial: string })[]

    selectedQuestion: AddExistingQuestionDraft['mappedQuestionDrafts'][number] | null = null

    // The serials of questions that have already been added to the scenario's list
    get scenarioSerialSet () {
        return new Set(this.orderedQuestionDrafts
            .filter((qd): qd is IParseQuestionDraft & { serial: string } => 'serial' in qd)
            .map(qd => qd.serial))
    }

    get mappedQuestionDrafts () {
        return this.jobQuestionDrafts
            .filter(qd => !this.scenarioSerialSet.has(qd.serial))   // Don't show questions already in the scenario
            .map(qd => {
                const prompt = stripHtmlTags(qd.prompt || '')
                const passage = stripHtmlTags(qd.passage || '')
                const promptTruncateLength = 60
                return {
                    label: `${qd.serial}: ${
                        prompt.length > promptTruncateLength ? '...' : ''
                    }${prompt.slice(-promptTruncateLength)}`,
                    serial: qd.serial,
                    prompt,
                    passage,
                    value: qd.objectId,
                }
            })
    }

    get isAddBtnDisabled () {
        return !this.selectedQuestion
    }

    cancel () {
        this.emitCancel()
    }

    add () {
        const selectedQuestionSerial = this.selectedQuestion?.serial
        if (!selectedQuestionSerial) {
            return
        }

        const selectedJobQuestionDraft = this.jobQuestionDrafts.find(qd => qd.serial === selectedQuestionSerial)
        if (selectedJobQuestionDraft) {
            this.emitAdd(selectedJobQuestionDraft)
        }
    }

    @Emit('cancel')
    emitCancel () {
        return true
    }

    @Emit('add')
    emitAdd (selectedJobQuestionDraft: IParseQuestionDraft & { serial: string }) {
        return selectedJobQuestionDraft
    }
}
</script>

<style lang="scss" scoped>
.add-existing-question-draft {
    display: flex;
    padding-top: 2px;
    padding-left: 30px;
    margin-bottom: 217px;

    &__select {
        width: 772px;
    }

    &__cancel-btn {
        margin-left: 10px;
        margin-right: 10px;
        padding: 8.5px 12px 7.5px;
        line-height: 20px;
    }

    &__add-btn {
        padding: 8.5px 14px 7.5px;
        line-height: 20px;
    }

    &__dropdown-column-labels {
        display: flex;
        justify-content: space-between;
        padding: 5px 11px;
        font-size: 13px;
        font-weight: 500;
        color: $slate;
    }

    &__dropdown-column-serial {
        width: 130px;
    }

    &__dropdown-column-prompt {
        width: 320px;
        padding-right: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        direction: rtl;
        text-align: left;

        // \200E character (&lrm;) allows left-to-right punctuation with left-side ellipsis
        &::after {
            content: '\200E';
        }
    }

    &__dropdown-column-passage {
        width: 320px;
        padding-right: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__dropdown-row {
        display: flex;
        justify-content: space-between;
    }
}
</style>
