<template>
    <JobViewSidebar 
        v-if="job"
        :sidebarPayload="sidebarPayload"
        :selectedJobStatus="selectedJobStatus"
        @jobStatusSelectionChanged="selectJobStatus"
    />
    <div class="job">
        <div class="job__content">
            <FormValidation :messages="validationMessages" />
            <template v-if="job && currentUser">
                <PocketButton
                    type="tertiary"
                    @click="$router.push('/')"
                >
                    Jobs
                </PocketButton>
                <div class="job__progress">
                    <TitleText
                        class="job__title-text"
                    >
                        {{ job.name }}
                    </TitleText>
                    <JobInfo 
                        v-if="job && !isLoading"
                        class="job__progress-bar" 
                        :job="job" 
                    />
                </div>
                <div>
                    <div class="job__job-subtext"> 
                        {{ job.type }} 
                        {{  job.type ? '|' : '' }}
                        {{ job.totalNewQuestionTemplates || job.totalReviewQuestions }} 
                        {{`Question${(job.totalNewQuestionTemplates || 0)  > 1
                            || (job.totalReviewQuestions|| 0) > 1 ? 's' : ''}`}}
                        {{ job.totalReviewQuestions 
                            ? 'in' 
                            : 'for' }} 
                        {{ mockExamDraft?.name || 'Standard Question Bank' }}
                    </div>
                </div>
                <div class="job__selected-status">{{ selectedJobStatus }}</div>
                <div 
                    class="job__subtitle-paragraph-text"
                    v-if="selectedJobStatus === 'Editor'"
                >
                    These questions are ready for
                    {{ currentUser.role === 'Editor' ? 'you' : 'the editor'}} to review.
                    Flagged questions are questions that were sent back to the writer and need re-checked by the editor.
                </div>
                <div 
                    v-else-if="selectedJobStatus === 'Approved'"
                    class="job__subtitle-paragraph-text"
                >
                    These questions have been approved by the editor.</div>
                <div v-if="selectedJobStatus === 'Writer'">
                    <QuestionTemplatesList
                        class="job__question-templates-list"
                        :question-templates="job.questionTemplates"
                        :question-drafts="questionDrafts"
                        :job-id="job.objectId"
                        :hide-create-button="job.isCompleted"
                        :currentUserRole="currentUser.role"
                    />
                    <div
                        class="job__subtitle-text"
                        :class="{'job__subtitle-text--extra-distance': selectedJobStatus === 'Writer'
                            && job.jobFunction !== 'Create Questions'}"
                    >
                        {{ listOptionsQuestionsToReview.listData.length }} Questions to Review
                        <div class="job__subtitle-paragraph-text">
                            This table includes questions not yet submitted to the Editor 
                            and those returned (flagged) for the writer to address issues.
                        </div>
                    </div>
                </div>
                <List
                    class="job__questions-to-review-list"
                    store-name="reviewQsPage"
                    :list-options="listOptionsQuestionsToReview"
                    :selectable="currentUser.role === 'Admin'"
                    :list-unit="'Question'"
                    :pre-selected-items="selectedQuestionDrafts"
                    :showLastPageBtn="true"
                    :key="reviewListKey"
                    @select="selectedQuestionDrafts = $event"
                    @itemClicked="questionClicked"
                    @activeFiltersChanged="updateActiveFilters"
                    v-if="selectedJobStatus && !isLoading"
                >
                    <template #listBulkActions v-if="currentUser.role === 'Admin'">
                        <div class="job__bulk-actions">
                            <BulkAction 
                                v-for="bulkActionGroup in bulkActionOptions"
                                :key="bulkActionGroup.actionGroupTitle"
                                :bulkActionGroupTitle="bulkActionGroup.actionGroupTitle"
                                :bulkActionGroupSubtitle="bulkActionGroup.subtitle"
                                :actions="bulkActionGroup.actions"
                                :extraWidth="bulkActionGroup.actionGroupTitle === 'Subject'"
                                @bulkActionSelected="bulkEditCheckedQuestions"
                                :disabled="!selectedQuestionDrafts.length"
                            >
                            </BulkAction>
                            <PocketButton
                                v-if="selectedJobStatus === 'Approved'"
                                type="secondary"
                                class="job__create-scenario-bulk-action"
                                :class="{ 'job__create-scenario-bulk-action--disabled': isScenarioBulkActionDisabled }"
                                :disabled="isScenarioBulkActionDisabled"
                                @click="createScenarioBulkActionClicked"
                            >
                                Create Scenario
                            </PocketButton>
                        </div>
                    </template>
                    <template #filterTypeDropdownListItem="{ item }">
                        <template v-if="item.label === 'Subject'">
                            <div class="job__filter-type-dropdown-item-container">
                                <div class="job__filter-type-dropdown-section-title">
                                    TABLE COLUMN
                                </div>
                                <div class="job__filter-type-dropdown-item">
                                    {{ item.label }}
                                </div>
                            </div>
                        </template>
                        <template v-if="item.label === 'Supplemental Info'">
                            <div class="job__filter-type-dropdown-item-container">
                                <div class="job__filter-type-dropdown-section-title">
                                    QUESTION FIELDS
                                </div>
                                <div class="job__filter-type-dropdown-item">
                                    {{ item.label }}
                                </div>
                            </div>
                        </template>
                        <template v-if="item.label === 'Flagged'">
                            <div class="job__filter-type-dropdown-item-container">
                                <div class="job__filter-type-dropdown-section-title">
                                    T/F FILTERS
                                </div>
                                <div class="job__filter-type-dropdown-item">
                                    {{ item.label }}
                                </div>
                            </div>
                        </template>
                    </template>
                    <template #filterTextDropdownListItem="{ item, field }">
                        <template v-if="field.propName === 'type'">
                            <div
                                v-if="item.label === 'MC'"
                                class="job__question-type-dropdown-item"
                            >
                                <div class="job__question-type-dropdown-item-label">
                                    MC
                                </div>
                                <div>
                                    Multiple Choice Question
                                </div>
                            </div>
                            <div
                                v-else-if="item.label === 'MCR'"
                                class="job__question-type-dropdown-item"
                            >
                                <div class="job__question-type-dropdown-item-label">
                                    MCR
                                </div>
                                <div>
                                    Multiple Correct Response
                                </div>
                            </div>
                            <div
                                v-else-if="item.label === 'mCB'"
                                class="job__question-type-dropdown-item"
                            >
                                <div class="job__question-type-dropdown-item-label">
                                    mCB
                                </div>
                                <div>
                                    Matrix Checkbox
                                </div>
                            </div>
                            <div
                                v-else-if="item.label === 'mRB'"
                                class="job__question-type-dropdown-item"
                            >
                                <div class="job__question-type-dropdown-item-label">
                                    mRB
                                </div>
                                <div>
                                    Matrix Radio Button
                                </div>
                            </div>
                            <div
                                v-if="item.label === 'TF'"
                                class="job__question-type-dropdown-item"
                            >
                                <div class="job__question-type-dropdown-item-label">
                                    TF
                                </div>
                                <div>
                                    True/False
                                </div>
                            </div>
                        </template>
                    </template>
                    <template
                        #appendListUnitText
                        v-if="isScenarioFilterActive"
                    >, {{ numScenariosInList }} Scenarios</template>
                    <template #tableColumnLabelText="{ column }">
                        <template v-if="column.propName === 'flagged'">Flag</template>
                        <template v-else-if="column.propName === 'plagiarismScore'">Plag.</template>
                        <template v-else-if="column.propName === 'type'">Type</template>
                        <template v-else-if="column.propName === 'percentCorrect'">Correct</template>
                        <template v-else-if="column.propName === 'isSpecial'">Cost/Status</template>
                        <template v-else-if="column.propName === 'hasCommentsOrSuggestions'">Suggestions</template>
                    </template>
                    <template #tableCellValue="{ row, column }">
                        <template v-if="column.propName === 'flagged'">
                            <div class="job__flagged-icon"
                            >
                                <Icon
                                    v-if="row.flagged === 'Yes'"
                                    type="flagFeedback"
                                    secondaryColor="#FFCE74"/>
                            </div>
                        </template>
                        <template v-if="column.propName === 'isSpecial' && row.isArchived === 'Yes'">
                            Archived
                        </template>
                        <template v-if="column.propName === 'hasCommentsOrSuggestions'">
                            <div class="job__suggestions-icons"
                            >
                                <img
                                    class="comment-icon"
                                    v-if="row.hasComments === 'Yes'"
                                    src="@/assets/img/chat-bubble-ckeditor-icon.svg"
                                    alt="chat bubble"
                                >
                                <img 
                                    v-if="row.hasSuggestions === 'Yes'" 
                                    src="@/assets/img/paper-pencil-ckeditor-icon.svg" 
                                    alt="paper and pencil"
                                >
                            </div>
                        </template>
                        <template v-if="column.propName === 'bloomTaxonomyLevel'">
                            <template v-if="row.bloomTaxonomyLevel === 'None'">
                                <div>--</div>
                            </template>
                        </template>
                        <template v-if="column.propName === 'lastUpdated'">
                            <template v-if="isToday(row.lastUpdated)">
                                {{ displayTime(row.lastUpdated) }}
                            </template>
                            <template v-else>
                                {{ formatDate(row.lastUpdated) }}
                            </template>
                        </template>
                    </template>
                    <template #emptyStateImage="{ activeFilterPropNames }">
                        <template v-if="activeFilterPropNames.length === 0 && selectedJobStatus === 'Writer'">
                            <img 
                                src="@/assets/img/brain-thumbs-up.png"
                                class="job__empty-state-image"
                            >
                        </template>
                        <template v-else-if="activeFilterPropNames.length === 0 && selectedJobStatus === 'Editor'">
                            <img 
                                src="@/assets/img/brain-thumbs-up.png"
                                class="job__empty-state-image"
                            >
                        </template>
                    </template>
                    <template #emptyStateTitle="{ activeFilterPropNames, searchAll }">
                        <template v-if="activeFilterPropNames.length === 1">
                            <template v-if="activeFilterPropNames.includes('isSpecial')">
                                0 Free Questions
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('isArchived')">
                                0 Archived Questions
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('explanationImage')">
                                0 Questions with Explanation Image
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('questionImage')">
                                0 Questions with Question Image
                            </template>
                            <template v-else>No Results</template>
                        </template>
                        <template v-else-if="activeFilterPropNames.length === 0">
                            <template v-if="searchAll.length">No Results</template>
                            <template v-else-if="selectedJobStatus === 'Approved'">
                                0 Approved Questions
                            </template>
                            <template v-else>
                                0 Questions to Review
                            </template>
                        </template>
                        <template v-else>No Results</template>
                    </template>
                    <template #emptyStateMessage="{ searchAll, activeFilterPropNames }">
                        <template v-if="searchAll">
                            All column fields are searchable except for answered and correct.
                        </template>
                        <template v-else-if="activeFilterPropNames.length === 1">
                            <template v-if="activeFilterPropNames.includes('isSpecial')">
                                There are no free questions in this exam.
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('isArchived')">
                                There are no archived questions in this exam.
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('explanationImage')">
                                No questions in this exam include an explanation image.
                            </template>
                            <template v-else-if="activeFilterPropNames.includes('questionImage')">
                                No questions in this exam include an image in the question prompt.
                            </template>
                        </template>
                        <template v-else-if="selectedJobStatus === 'Writer'">
                            <template></template>
                        </template>
                        <template v-else-if="activeFilterPropNames.length === 0 && selectedJobStatus === 'Editor'">
                            <template v-if="currentUser.role !== 'Editor'">
                                <template></template>
                            </template>
                            <template v-else-if="currentUser.role === 'Editor' && !job.totalCompletedQuestions">
                                When the writer submits questions, they will appear here for you to review.
                            </template>
                            <template v-else-if="currentUser.role === 'Editor' && job.totalCompletedQuestions">
                                You've reviewed all the questions written by the writer.
                            </template>
                        </template>
                        <template v-else-if="activeFilterPropNames.length === 0 && selectedJobStatus === 'Approved'">
                            When the editor approves questions, they will appear here.
                        </template>
                    </template>
                </List>
                <Toast
                    v-if="toast"
                    class="job__toast"
                    :right="true"
                    @close="toast = null"
                >
                    {{ toast }}
                </Toast>
            </template>
            <div v-if="error" class="job__error">
                {{ error }}
            </div>
            <div v-if="!job && !isLoading">
                Job {{ $route.params.jobId }} not found
            </div>
            <ButtonFooter v-if="job" class="job__button-footer">
                <template 
                    #center 
                >
                    <div class="job__center-buttons">
                        <ChatButton
                            v-if="!isLoading && job"
                            class="job__chat-button"
                            :job-id="$route.params.jobId"
                        />
                    </div>
                </template>
                <template
                    #rightSide 
                >
                    <div class="job__right-side-buttons">
                        <PocketButton
                            v-if="isAdmin"
                            class="job__edit-job-button"
                            type="secondary"
                            @click="editJobClicked"
                        >
                            Edit Job
                        </PocketButton>
                        <PocketButton
                            v-if="isAdmin"
                            type="primary"
                            :disabled="job.isCompleted"
                            @click="submitCompleteJob"
                        >
                            Complete Job
                        </PocketButton>
                    </div>
                </template>
            </ButtonFooter>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'
import { FormGroup, FormSection, FormValidation, SelectField } from '@/components/Forms/'
import QuestionTemplatesList from './QuestionTemplatesList.vue'
import {
    List,
    type IListOptions,
    type TSchema,
    type TListDataModifier,
    type TListDataIconFunc,
    type TListData,
} from '@/components/Lists'
import JobViewSidebar from '@/components/Sidebars/JobViewSidebar.vue'
import Loading from '@/components/Loading.vue'
import JobInfo from '@/components/Jobs/JobInfo.vue'
import { objPointer } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import type { IMappedQuestionDraft, IParseQuestionDraft } from '@/store/questionDrafts/types'
import { usersModule } from '@/store/users/module'
import questionDraftsModule from '@/store/questionDrafts/module'
import sidebarModule from '@/store/sidebar/module'
import jobsModule from '@/store/jobs/module'
import examDraftsModule from '@/store/examDrafts/module'
import kaDraftsModule from '@/store/knowledgeAreaDrafts/module'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import ButtonFooter from '@/components/ButtonFooter.vue'
import TitleText from '@/components/TitleText.vue'
import BulkAction from '@/components/BulkAction.vue'
import Chat from '@/components/Chat/Chat.vue'
import ChatButton from '@/components/Chat/ChatButton.vue'
import UIKit from '@pocketprep/ui-kit'
import BrandColors from '@pocketprep/ui-kit/pocketprep-export.module.scss'
import { bloomTaxonomyLevels, formatDate } from '@/utils'
import type { IJobSidebarPayload } from '@/store/jobs/types'
import questionScenarioDraftsModule from '@/store/questionScenarioDrafts/module'
import { toastModule } from '@/store/toast/module'

@Component({
    components: {
        BulkAction,
        FormGroup,
        FormSection,
        QuestionTemplatesList,
        Loading,
        List,
        JobInfo,
        JobViewSidebar,
        FormValidation,
        SelectField,
        ButtonFooter,
        TitleText,
        Chat,
        PocketButton: UIKit.Button,
        Icon: UIKit.Icon,
        Toast: UIKit.Toast,
        ChatButton,
    },
})
class JobView extends Vue {
    mockExamDraft: null | CMS.Class.MockExamDraftJSON = null
    editing = false
    isLoading = true
    error: string | null = null
    selectedQuestionDrafts: IMappedQuestionDraft[] = []
    knowledgeAreaDrafts: CMS.Class.KnowledgeAreaDraftJSON[] = []
    questionScenarioDrafts: CMS.Class.QuestionScenarioDraftJSON[] = []
    validationMessages: string[] = []
    questionDrafts: CMS.Class.QuestionDraftJSON[] = []
    bulkQuestionAction = ''
    showChat = false
    selectedJobStatus: 'Writer' | 'Editor' | 'Approved' | null = 'Writer'
    brandColors = BrandColors
    toast: string | null = null
    bulkActionListKey = ''
    activeFilters: string[] = []

    get isScenarioBulkActionDisabled () {
        return !this.job?.allowScenarios
            || !this.selectedQuestionDrafts?.length
            || this.selectedQuestionDrafts.some(qd => qd.isScenario === 'Yes')
    }

    get bulkActionOptions () {
        const bulkActionsSchema = [
            {
                actionGroupTitle: 'Queue',
                subtitle: 'Change Queue',
                actions: [],
            },
            {
                actionGroupTitle: 'Subject',
                subtitle: this.knowledgeAreaDrafts.every(kad => kad.subtopics && kad.subtopics.length)
                    ? 'Change Subtopic To'
                    : 'Change Subject To',
                actions: this.knowledgeAreaDrafts
                    .map(ka => ({
                        label: `${ka.name}${ka.isArchived ? ' (archived)' : ''}`,
                        value: `knowledgearea_${ka.objectId}`,
                        subActions: ka.subtopics?.map(subtopic => ({
                            label: subtopic.name,
                            value: `knowledgearea_${ka.objectId}_subtopic_${subtopic.id}`,
                        })),
                    }))
                    .sort((a, b) =>
                        a.label.toLowerCase() < b.label.toLowerCase()
                            ? -1
                            : a.label.toLowerCase() > b.label.toLowerCase()
                                ? 1
                                : 0
                    ),
            },
            {
                actionGroupTitle: 'Status',
                subtitle: 'Change Status to',
                actions: [
                    { label: 'Archived Question', value: 'archive' },
                    { label: 'Active Question', value: 'unarchive' },
                ],
            },
            {
                actionGroupTitle: 'Cost',
                subtitle: 'Change Cost to',
                actions: [
                    { label: 'Free Question', value: 'free' },
                    { label: 'Premium Question', value: 'premium' },
                ],
            },
        ]

        if (this.selectedJobStatus === 'Approved') {
            bulkActionsSchema[0].actions = [
                { label: 'Flag to Writer', value: 'writer' },
                { label: 'Back to Editor', value: 'editor' },
            ]
        } else if (this.selectedJobStatus === 'Editor') {
            bulkActionsSchema[0].actions = [
                { label: 'Flag to Writer', value: 'writer' },
                { label: 'Approve', value: 'completed' },
            ]
        } else if (this.selectedJobStatus === 'Writer') {
            bulkActionsSchema[0].actions = [
                { label: 'Submit to Editor', value: 'editor' },
                { label: 'Approve', value: 'completed' },
            ]
        }

        return bulkActionsSchema
    }

    get sidebarPayload () {
        const sidebarSchema: IJobSidebarPayload = {
            jobType: this.job?.jobFunction,
            loggedAs: this.currentUser?.role,
            numOfQuestionDrafts: this.questionDrafts.length,
            numOfEditorQuestions: this.questionDrafts.filter(qd => qd.jobStatus === 'Editor').length,
            numOfTotalNewQuestionTemplates: this.job?.totalNewQuestionTemplates,
            numOfTotalCompletedQuestions: this.job?.totalCompletedQuestions,
            numOfQuestionsToReview: this.job?.totalReviewQuestions,
            writerPercent: this.job?.writerPercent,
            dueDate: this.job?.dueDate ? formatDate(this.job?.dueDate) : '--',
            writerName: this.job?.writerId ? this.getDisplayName(this.job?.writerId) : '',
            editorName: this.job?.editorId ? this.getDisplayName(this.job?.editorId) : '',
            leadName: this.job?.leadId ? this.getDisplayName(this.job?.leadId) : '',
            allowedQuestionTypes: this.allowedQuestionTypeNames,
            keywordStyling: this.job?.keywordStyling,
            notesAndLinks: this.job?.notesAndLinks,
            plagiarismScore: this.avgPlagiarismScore,
            allowScenarios: this.job?.allowScenarios || false,
        }

        return sidebarSchema
    }

    get reviewListKey () {
        const questionDrafts = (this.job && this.questionDrafts) || []
        const listData = questionDrafts
            .filter(questionDraft =>
                this.selectedJobStatus === 'Writer'
                && questionDraft.jobStatus === 'Writer'
                || (
                    this.selectedJobStatus === 'Editor'
                    && questionDraft.jobStatus === 'Editor'
                )
                || (
                    this.selectedJobStatus === 'Approved'
                    && questionDraft.jobStatus === 'Completed'
                )
            )
            .map(question => question.serial || question.objectId)
        const stringifiedQuestionSerials = JSON.stringify(listData)

        return `${stringifiedQuestionSerials}-${this.bulkActionListKey}`
    }

    get isPlagScoreHidden () {
        if (this.selectedJobStatus === 'Writer' && this.job?.jobFunction !== 'Review Questions') {
            return true
        }
        if (!this.job?.runPlagiarismCheck) {
            return true
        }

        return false
    }

    get subjectColumnWidth () {
        if (this.knowledgeAreaDrafts.some(kad => kad.subtopics && kad.subtopics.length)) {
            if (this.currentUser?.role === 'Admin' && this.selectedJobStatus === 'Approved') {
                return 213
            } else {
                if (this.job?.jobFunction === 'Create Questions' && this.currentUser?.role === 'Admin') {
                    return 228
                } else {
                    return 195
                }
            }
        } else {
            return 285
        }
    }

    get subtopicColumnWidth () {
        if (
            (this.selectedJobStatus === 'Editor' && this.currentUser?.role !== 'Admin')
            || this.job?.jobFunction === 'Review Questions'
            || this.selectedJobStatus === 'Approved' && this.currentUser?.role === 'Admin'
        ) {
            if (this.job?.runPlagiarismCheck) {
                if (this.selectedJobStatus === 'Approved' && this.currentUser?.role !== 'Admin') {
                    return 156
                } else {
                    return 100
                }
            } else {
                if (this.selectedJobStatus !== 'Approved') {
                    return 156
                } else {
                    if (this.isPlagScoreHidden && this.currentUser?.role !== 'Admin') {
                        return 156
                    } else {
                        return 100
                    }
                }
            }
        } else {
            return 180
        }
    }

    get promptColumnWidth () {
        if (this.selectedJobStatus === 'Writer') {
            if (this.job?.jobFunction === 'Review Questions') {
                return 362
            } else if (this.currentUser?.role === 'Admin') {
                if (this.knowledgeAreaDrafts.every(kad => !kad.subtopics)) {
                    return 561
                } else {
                    return 436
                }
            } else if (this.knowledgeAreaDrafts.every(kad => !kad.subtopics)) {
                return 559
            } else {
                return 471
            }
        } else if (this.selectedJobStatus === 'Editor') {
            if (this.job?.jobFunction === 'Review Questions') {
                return 362
            } else if (this.currentUser?.role === 'Admin') {
                if (this.knowledgeAreaDrafts.every(kad => !kad.subtopics)){
                    if (this.isPlagScoreHidden) {
                        return 561
                    } else {
                        return 495
                    }
                } else {
                    if (this.isPlagScoreHidden) {
                        return 436
                    } else {
                        return 380
                    }
                }
            } else if (this.isPlagScoreHidden) {
                return 493
            } else {
                return 493
            }
        } else if (this.selectedJobStatus === 'Approved') {
            if (this.job?.jobFunction === 'Review Questions') {
                if (this.currentUser?.role === 'Admin') {
                    return 226
                } else {
                    if (this.currentUser?.role !== 'Writer') {
                        if (this.isPlagScoreHidden) {
                            return 456
                        } else {
                            return 402
                        }
                    } else {
                        return 481
                    }
                }
            } else if (this.currentUser?.role === 'Admin') {
                if (this.isPlagScoreHidden) {
                    return 400
                } else {
                    return 357
                }
            } else {
                if (this.isPlagScoreHidden) {
                    return 590
                } else {
                    return 535
                }
            }
        } else {
            return 471
        }
    }

    get isAdmin () {
        return usersModule.getters.getIsAdmin()
    }

    get jobs () {
        return jobsModule.state.jobs
    }

    get currentUser () {
        return usersModule.state.user
    }

    get newQuestionGoal () {
        return (this.job && this.job.totalNewQuestionTemplates) || 0
    }

    get reviewQuestionGoal () {
        return (this.job && this.job.totalReviewQuestions) || 0
    }

    get allowedQuestionTypeNames () {
        if (this.job) {
            const aqt = this.job.allowedQuestionTypes
            return [
                aqt.mcq?.allowed && 'MCQ',
                aqt.mcr?.allowed && 'MCR',
                aqt.mcb?.allowed && 'mCB',
                aqt.mrb?.allowed && 'mRB',
                aqt.tf?.allowed && 'TF',
            ].filter(aqtName => !!aqtName).join(', ')
        } else {
            return 'None'
        }
    }

    get avgPlagiarismScore () {
        const totalScore = this.questionDrafts.reduce((acc, qd) => {
            if (qd.copyleaksScore && 'aggregatedScore' in qd.copyleaksScore) {
                acc.accumScore += qd.copyleaksScore.aggregatedScore
                acc.questionCount += 1
            }
            return acc
        }, { questionCount: 0, accumScore: 0 })

        return totalScore.questionCount
            ? (totalScore.accumScore / totalScore.questionCount).toFixed(2)
            : 0
    }

    selectJobStatus (status: 'Writer' | 'Editor' | 'Approved') {
        this.activeFilters = []
        this.selectedJobStatus = null
        this.$nextTick(() => {
            this.selectedJobStatus = status
            sidebarModule.state.lastSelectedJobStatus = status
        })
    }

    async bulkEditCheckedQuestions (action: string) {
        this.validationMessages = []
        this.toast = null
        this.bulkActionListKey = Math.floor(Math.random() * 1e7).toString()

        let questionMap
        let toastMessage
        if (action === 'writer') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                jobStatus: 'Writer' as const,
                isFlagged: true,
            })
            toastMessage = `${this.selectedQuestionDrafts.length} questions flagged back to writer`
        } else if (action === 'editor') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                jobStatus: 'Editor' as const,
            })
            toastMessage = `${this.selectedQuestionDrafts.length} questions assigned to editor`
        } else if (action === 'completed') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                isFlagged: false,
                objectId: q.objectId,
                jobStatus: 'Completed' as const,
            })
            toastMessage = `${this.selectedQuestionDrafts.length} questions approved`
        } else if (action === 'archive') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                isArchived: true,
            })
            toastMessage = `${this.selectedQuestionDrafts.length} questions archived`
        } else if (action === 'unarchive') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                isArchived: false,
            })
            toastMessage = `${this.selectedQuestionDrafts.length} questions unarchived`
        } else if (action === 'free') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                isSpecial: true,
            })
            toastMessage = `Cost changed to Free for ${this.selectedQuestionDrafts.length} questions`
        } else if (action === 'premium') {
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                isSpecial: false,
            })
            toastMessage = `Cost changed to Premium for ${this.selectedQuestionDrafts.length} questions`
        } else if (action === 'tab-created') {
            this.selectedQuestionDrafts.forEach(q =>
                window.open('/question-drafts/' + q.objectId, '_blank')
            )
            this.selectedQuestionDrafts = []
            return
        } else if (action.includes('knowledgearea_')) {
            const kaId = action.split('_')[1]
            let subtopicId = ''
            if (action.includes('subtopic_')){
                subtopicId = action.split('_')[3]
            }
            questionMap = (q: IMappedQuestionDraft): Partial<IParseQuestionDraft> => ({
                objectId: q.objectId,
                knowledgeAreaDraft: { objectId: kaId, __type: 'Pointer', className: 'KnowledgeAreaDraft' },
                subtopicId,
            })
            const kaName = this.knowledgeAreaDrafts.find(ka => ka.objectId === kaId)?.name
            toastMessage = `Subject changed to ${kaName} for ${this.selectedQuestionDrafts.length} questions`
        }

        if (!questionMap) {
            this.validationMessages.push('error/Invalid bulk action')
            return
        }

        this.selectedQuestionDrafts.length &&
            await questionDraftsModule.actions.upsertQuestionDrafts(
                this.selectedQuestionDrafts.map(questionMap)
            )

        await jobsModule.actions.fetchJob((this.job as CMS.Cloud.JobWithMetrics).objectId),
        this.questionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
            equalTo: {
                job: objPointer((this.job as CMS.Cloud.JobWithMetrics).objectId)('Job'),
            },
        })).results
        this.selectedQuestionDrafts = []
        this.toast = toastMessage || null
    }

    async createScenarioBulkActionClicked () {
        if (this.selectedQuestionDrafts.length && this.examDraft) {
            const newKey = this.generateNextScenarioKey()
            const passage = this.selectedQuestionDrafts[0].passage
            const questionScenarioDraft = await questionScenarioDraftsModule.actions.createQuestionScenarioDraft({
                examDraftId: this.examDraft.objectId,
                key: newKey,
                questionDrafts: this.selectedQuestionDrafts.map(qd => ({
                    serial: qd.serial || qd.objectId,
                })),
                sharedPassage: passage,
            })
            await Promise.all(this.selectedQuestionDrafts.map(qd =>
                questionDraftsModule.actions.updateScenarioDraftOnQuestionDraft({
                    questionDraftId: qd.objectId,
                    newScenarioDraftId: questionScenarioDraft.objectId,
                }))
            )
            await jobsModule.actions.fetchJob((this.job as CMS.Cloud.JobWithMetrics).objectId),
            this.questionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
                equalTo: {
                    job: objPointer((this.job as CMS.Cloud.JobWithMetrics).objectId)('Job'),
                },
            })).results
            this.questionScenarioDrafts = await questionScenarioDraftsModule.actions
                .fetchQuestionScenarioDraftsByExamDraftId(this.examDraft.objectId)
            toastModule.actions.displayToast({
                title: `Scenario ${questionScenarioDraft.key} created: ${this.selectedQuestionDrafts.length} questions`,
            })
            this.selectedQuestionDrafts = []
        }
    }

    get examDraft () {
        return (this.job && this.job.examDraftId)
            ? examDraftsModule.getters.getExamDraft(this.job.examDraftId)
            : undefined
    }

    generateNextScenarioKey () {
        // Check that we've already created the exam draft to minimize risk of creating duplicate keys
        if (!this.examDraft?.objectId) {
            throw new Error('Cannot create a scenario draft without an exam draft')
        }

        if (!this.questionScenarioDrafts.length) {
            return '001'
        }

        const numericScenarioKeys = this.questionScenarioDrafts.map(scenarioDraft => Number(scenarioDraft.key))
        const sortedKeys = numericScenarioKeys.sort((a, b) => a - b)
        const nextKey = sortedKeys.reduce((acc, key) => {
            if (key === acc) {
                return acc + 1  // If the key is already used, try one higher
            } else {
                return acc  // If the key isn't already used, use it
            }
        }, 1)
        const formattedKey = String(nextKey).padStart(3, '0')
        return formattedKey
    }

    formatDate = formatDate

    isToday (date: string) {
        const currentDate = new Date()
        const givenDate = new Date(date)
        const isToday = givenDate.getFullYear() === currentDate.getFullYear() &&
                givenDate.getMonth() === currentDate.getMonth() &&
                givenDate.getDate() === currentDate.getDate()
        return isToday ? true : false
    }

    displayTime (date: string) {
        const givenDate = new Date(date)
            .toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        return givenDate.replace(/\s+/g, '').toLowerCase()
    }

    getDisplayName (userId: string) {
        return usersModule.getters.getDisplayName(userId)
    }

    mapQuestionDraft ({
        objectId,
        type,
        serial,
        prompt,
        jobStatus,
        examDataId,
        isFlagged,
        hasSuggestions,
        knowledgeAreaDraft,
        hasComments,
        isArchived,
        updatedAt,
        isSpecial,
        explanation,
        passage,
        references,
        answeredCorrectlyCount,
        answeredIncorrectlyCount,
        percentCorrect,
        willResetMetrics,
        images,
        copyleaksScore,
        bloomTaxonomyLevel,
        subtopicId,
        questionScenarioDraft,
    }: CMS.Class.QuestionDraftJSON): IMappedQuestionDraft {
        const knowledgeAreaObj = this.knowledgeAreaDrafts
            .find(ka => !!(knowledgeAreaDraft) && ka.objectId === knowledgeAreaDraft.objectId)
        const subtopic = subtopicId && knowledgeAreaObj?.subtopics?.find(sub => sub.id === subtopicId)
        const scenarioObj = this.questionScenarioDrafts.find(scenarioDraft =>
            scenarioDraft.objectId === questionScenarioDraft?.objectId
        )
        const indexOrderInScenario = scenarioObj?.questionDrafts.findIndex(qd => qd.serial === serial)
        const formattedScenarioInfo = (scenarioObj && typeof indexOrderInScenario === 'number')
            ? `${scenarioObj.key} • ${indexOrderInScenario + 1}`
            : null
        return {
            objectId,
            isSpecial: isSpecial ? 'Free' : 'Premium',
            jobStatus,
            knowledgeAreaName: (knowledgeAreaObj && knowledgeAreaObj.name) || '',
            newQuestion: examDataId ? 'No' : 'Yes',
            prompt: prompt || '',
            flagged: isFlagged ? 'Yes' : 'No',
            hasComments: hasComments ? 'Yes' : 'No',
            hasSuggestions: hasSuggestions ? 'Yes' : 'No',
            hasCommentsOrSuggestions: hasComments || hasSuggestions ? 'Yes' : 'No',
            noReference: references && references.length ? 'No' : 'Yes',
            isArchived: isArchived ? 'Yes' : 'No',
            lastUpdated: typeof updatedAt === 'string' && updatedAt || '',
            type: type === 'Multiple Choice' 
                ? 'MC'
                : type === 'Multiple Correct Response' 
                    ? 'MCR'
                    : type === 'Matrix Checkbox'
                        ? 'mCB'
                        : type === 'Matrix Radio Button'
                            ? 'mRB'
                            : 'TF',
            serial: serial || '',
            explanation: explanation || '',
            passage: passage || '',
            references: references ? references.join(' ') : '',
            selected: this.selectedQuestionDrafts.find(sqd => sqd.objectId === objectId) ? 'Yes' : 'No',
            answered: (answeredCorrectlyCount || 0) + (answeredIncorrectlyCount || 0),
            percentCorrect: percentCorrect || 0,
            willResetMetrics: !!willResetMetrics,
            explanationImage: images?.explanation ? 'Yes' : 'No',
            questionImage: images?.passage ? 'Yes' : 'No',
            plagiarismScore: copyleaksScore?.aggregatedScore === undefined ? 'n/a' : copyleaksScore?.aggregatedScore,
            questionBank: this.mockExamDraft ?
                this.mockExamDraft.acronym
                    ? this.mockExamDraft.acronym
                    : this.formatMockExamName(this.mockExamDraft.name)
                : 'SQB',
            bloomTaxonomyLevel: bloomTaxonomyLevel || 'None',
            subtopic: subtopic ? subtopic.name : '',
            isScenario: formattedScenarioInfo ? 'Yes' : 'No',
            scenarioInfo: formattedScenarioInfo || '',
        }
    }

    get listSchemaAndModifiers () {
        const unarchivedSubjects = this.knowledgeAreaDrafts.filter(kaDraft => !kaDraft.isArchived)
        const isSubtopicsAvailable = this.knowledgeAreaDrafts.some(kad => kad.subtopics && kad.subtopics.length)
        let listSchema: TSchema<IMappedQuestionDraft> = [
            {
                propName: 'scenarioInfo',
                label: 'Scenario',
                type: 'text',
                options: {
                    isHidden: !this.isScenarioFilterActive,
                    filter: false,
                    width: 140,
                    labelStyles: this.currentUser?.role === 'Admin' ? { paddingLeft: '49px' } : {},
                },
            },
            {
                propName: 'knowledgeAreaName',
                label: 'Subject',
                exactMatch: true,
                type: 'text',
                options: {
                    style: 'overflow-ellipsis',
                    width: this.subjectColumnWidth,
                    labelStyles: !this.isScenarioFilterActive && this.currentUser?.role === 'Admin'
                        ? { paddingLeft: '49px' }
                        : {},
                },
                data: unarchivedSubjects.map(ka => ka.name).sort((a, b) => 
                    a.toLowerCase().localeCompare(b.toLowerCase(), undefined, { numeric: true })
                ),
            },
            {
                propName: 'subtopic',
                label: 'Subtopic',
                type: 'text',
                options: {
                    isHidden: !isSubtopicsAvailable,
                    filter: isSubtopicsAvailable,
                    width: this.subtopicColumnWidth,
                },
                data: unarchivedSubjects.flatMap(kaDraft => kaDraft.subtopics?.map(sub => sub.name) || []),
            },
            {
                propName: 'bloomTaxonomyLevel',
                label: 'Bloom\'s',
                type: 'text',
                options: {
                    width: 104,
                    isHidden: this.selectedJobStatus !== 'Approved' && this.isScenarioFilterActive,
                },
                data: bloomTaxonomyLevels,
            },
            {
                propName: 'type',
                label: 'Question type',
                type: 'text',
                options: {
                    group: 0,
                    width: 60,
                },
                data: [ 'MC', 'MCR', 'mCB', 'mRB', 'TF' ],
            },
            {
                propName: 'prompt',
                label: 'Question prompt',
                type: 'text',
                options: {
                    style: 'overflow-ellipsis',
                    width: this.promptColumnWidth,
                },
            },
            {
                propName: 'answered',
                label: 'Answered',
                type: 'text',
                options: {
                    width: 75,
                    isHidden: this.job?.jobFunction !== 'Review Questions',
                    filter: false,
                    labelStyles: { paddingLeft: '0', textAlign: 'left', paddingRight: '0' },
                    fieldStyles: { textAlign: 'right', paddingLeft: '0', paddingRight: '16px' },
                },
            },
            {
                propName: 'percentCorrect',
                label: '% Correct',
                type: 'percent',
                options: {
                    width: 58,
                    isHidden: this.job?.jobFunction !== 'Review Questions',
                    filter: this.currentUser?.role === 'Admin' && this.job?.jobFunction === 'Review Questions',
                    labelStyles: { margin: '0', paddingRight: '0', textAlign: 'left' },
                },
            },
            {
                propName: 'plagiarismScore',
                label: 'Plagiarism score',
                type: 'percent',
                options: {
                    isHidden: this.isPlagScoreHidden,
                    width: 56,
                    filter: this.currentUser?.role === 'Admin' && this.job?.jobFunction === 'Review Questions',
                    labelStyles: { margin: '0', paddingRight: '0', textAlign: 'right', paddingLeft: '0px' },
                },
            },
            {
                propName: 'questionBank',
                label: 'Bank',
                type: 'text',
                options: {
                    isHidden: this.selectedJobStatus !== 'Approved' || this.currentUser?.role !== 'Admin',
                    filter: false,
                    fieldStyles: { paddingLeft: '0' },
                },
            },
            {
                propName: 'passage',
                label: 'Supplemental Info',
                type: 'text',
                options: {
                    isHidden: true,
                },
            },
            {
                propName: 'explanation',
                label: 'Explanation',
                type: 'text',
                options: {
                    isHidden: true,
                },
            },
            {
                propName: 'references',
                label: 'References',
                type: 'text',
                options: {
                    isHidden: true,
                },
            },
            {
                propName: 'flagged',
                label: 'Flagged',
                type: 'text',
                options: {
                    width: 46,
                    isHidden: this.selectedJobStatus === 'Approved',
                    filterValueWhenActive: 'Yes',
                    fieldStyles: { paddingLeft: '0', textAlign: 'center' },
                    labelStyles: {
                        paddingLeft: '6px',
                    },
                },
                data: [ 'Yes', 'No' ],
            },
            {
                propName: 'noReference',
                label: 'No reference',
                type: 'text',
                options: {
                    filterValueWhenActive: 'Yes',
                    isHidden: true,
                },
                data: [ 'Yes', 'No' ],
            },
            {
                propName: 'hasCommentsOrSuggestions',
                label: 'Has suggestions',
                type: 'text',
                options: {
                    filter: this.currentUser?.role === 'Admin',
                    sort: false,
                    width: 74,
                    labelStyles: {
                        paddingLeft: '0px',
                    },
                    filterValueWhenActive: 'Yes',
                    isHidden: this.selectedJobStatus === 'Approved' ? true : this.isScenarioFilterActive,
                },
                data: [ 'Yes', 'No' ],
            },
            {
                propName: 'isScenario',
                label: 'Scenario question',
                type: 'text',
                options: {
                    isHidden: true,
                    filterValueWhenActive: 'Yes',
                },
                data: [ 'Yes', 'No' ],
            },
        ]

        if (this.currentUser?.role === 'Admin') {
            const adminFilters: TSchema<IMappedQuestionDraft> = [
                {
                    propName: 'selected',
                    label: 'Selected',
                    type: 'text',
                    options: {
                        isHidden: true,
                        filterValueWhenActive: 'Yes',
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'isSpecial',
                    label: 'Free question',
                    type: 'text',
                    options: {
                        width: 84,
                        filterValueWhenActive: 'Free',
                        isHidden: this.isScenarioFilterActive ? true : this.selectedJobStatus !== 'Approved',
                        labelStyles: { paddingLeft: '0' },
                        fieldStyles: { paddingLeft: '0' },
                    },
                    data: [ 'Premium', 'Free' ],
                },
                {
                    propName: 'isArchived',
                    label: 'Archived question',
                    type: 'text',
                    options: {
                        isHidden: true,
                        filterValueWhenActive: 'Yes',
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'explanationImage',
                    label: 'Has explanation image',
                    type: 'text',
                    options: {
                        isHidden: true,
                        filterValueWhenActive: 'Yes',
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'questionImage',
                    label: 'Has question image',
                    type: 'text',
                    options: {
                        isHidden: true,
                        filterValueWhenActive: 'Yes',
                    },
                    data: [ 'Yes', 'No' ],
                },
                {
                    propName: 'lastUpdated',
                    label: 'Updated',
                    type: 'date',
                    options: {
                        reversedSort: true,
                        filter: false,
                        isHidden: this.selectedJobStatus !== 'Approved',
                        width: 96,
                        labelStyles: { paddingLeft: '0', paddingRight: '9px', textAlign: 'right' },
                        fieldStyles: { paddingLeft: '0', textAlign: 'right', paddingRight: '9px' },
                    },
                },
            ]

            listSchema = [ ...listSchema, ...adminFilters ]
        }

        const listDataIcons: TListDataIconFunc<IMappedQuestionDraft>[] = [
            data => {
                if (data.jobStatus === 'Writer') {
                    return {
                        iconName: 'pencil',
                        label: 'Status: ' + data.jobStatus,
                        styles: { color: '#fff', backgroundColor: '#ff731a' },
                    }
                } else if (data.jobStatus === 'Editor') {
                    return {
                        iconName: 'edit',
                        label: 'Status: ' + data.jobStatus,
                        styles: { color: '#fff', backgroundColor: '#e2e106' } }
                } else if (data.jobStatus === 'Completed') {
                    return {
                        iconName: 'check',
                        label: 'Status: ' + data.jobStatus,
                        styles: { color: '#fff', backgroundColor: '#92c73d' },
                    }
                }

                return false
            },
        ]

        let listDataModifiers: TListDataModifier<IMappedQuestionDraft>[] = []

        if (this.currentUser?.role === 'Editor') {
            listDataModifiers = [
                data => {
                    if (data.jobStatus === 'Writer' && !(data.flagged === 'Yes')) {
                        return { 
                            color: `${this.brandColors.slate}`,
                            cursor: 'default',
                            pointerEvents: 'none' }
                    } else return false
                },
            ]
        }
        listDataModifiers.push((data: IMappedQuestionDraft) =>
            data.isArchived === 'Yes' && { color: this.brandColors.pewter })

        return {
            listSchema,
            listDataModifiers,
            listDataIcons,
        }
    }

    get listOptionsQuestionsToReview (): IListOptions<IMappedQuestionDraft> {
        const questionDrafts = (this.job && this.questionDrafts) || []
        const listData: IMappedQuestionDraft[] = questionDrafts
            .filter(questionDraft =>
                this.selectedJobStatus === 'Writer'
                && questionDraft.jobStatus === 'Writer'
                || (
                    this.selectedJobStatus === 'Editor'
                    && questionDraft.jobStatus === 'Editor'
                )
                || (
                    this.selectedJobStatus === 'Approved'
                    && questionDraft.jobStatus === 'Completed'
                )
            )
            .map(this.mapQuestionDraft)

        const {
            listSchema,
            listDataModifiers,
            listDataIcons,
        } = this.listSchemaAndModifiers

        return {
            listData,
            listSchema,
            listDataModifiers,
            listDataIcons,
            defaultSort: {
                sortDir: 'ASC',
                propName: this.isScenarioFilterActive ? 'scenarioInfo' : 'lastUpdated',
            },
        }
    }

    get numScenariosInList () {
        const listData = this.listOptionsQuestionsToReview.listData as IMappedQuestionDraft[]
        const scenarioSet = new Set<string>()   // unique scenario keys
        listData.forEach(q => {
            if (q.scenarioInfo) {
                const scenarioKey = q.scenarioInfo.split(' ')[0]
                scenarioSet.add(scenarioKey)
            }
        })
        return scenarioSet.size
    }

    formatMockExamName (name: string) {
        // formatting example "Mock Exam III" to "MEIII"
        const words = name.split(' ')
        const lastThreeWords = words.slice(-3)
        const result = `${lastThreeWords[0][0]}${lastThreeWords[1][0]}${lastThreeWords[2]}`

        return result
    }

    questionClicked (question: TListData) {
        if (this.currentUser?.role === 'Editor' && question.jobStatus === 'Writer' && !question.flagged) {
            return
        }
        this.$router.push({
            name: 'question-draft-edit',
            params: { questionDraftId: String(question.objectId) },
        })
    }

    async submitCompleteJob () {
        if (!this.job) {
            throw 'Job not found. Unable to complete job.'
        }

        const confirmDialog = confirm(
            'Completing this job will change the status of all questions to complete' +
            ' and remove the job from the writer and editor\'s queues. This cannot be undone. Continue?'
        )
        if (confirmDialog) {
            this.isLoading = true
            await jobsModule.actions.completeJob(this.job.objectId)
            this.isLoading = false
            this.$router.push({ name: 'job-list' })
        }
    }

    editJobClicked () {
        if (this.job && this.job.objectId) {
            this.$router.push({
                name: 'job-edit',
                params: { jobId: this.job.objectId },
            })
        }
    }

    toggleEditing () {
        this.editing = !this.editing
    }

    get job () {
        const jobId = this.$route.params.jobId

        return this.jobs.find((job) => job.objectId === jobId)
    }

    get isScenarioFilterActive () {
        return this.activeFilters.includes('isScenario')
    }

    async mounted () {
        this.isLoading = true

        // fetch job
        if (!this.job) {
            const jobId = typeof this.$route.params.jobId === 'string'
                ? this.$route.params.jobId
                : this.$route.params.jobId[0]
            await jobsModule.actions.fetchJob(jobId)
        }

        // if still no job, redirect to dashboard
        if (!this.job) {
            await this.$router.push({
                name: 'job-list',
            })
            return
        }

        // if not a writer or editor on job or an admin, redirect to dashboard
        if (
            this.currentUser?.objectId !== this.job.writerId &&
            this.currentUser?.objectId !== this.job.editorId &&
            this.currentUser?.role !== 'Admin'
        ) {
            await this.$router.push({
                name: 'job-list',
            })
            return
        }

        if (sidebarModule.state.lastSelectedJobStatus) {
            this.selectedJobStatus = sidebarModule.state.lastSelectedJobStatus
        } else if (this.currentUser?.role === 'Admin') {
            if (this.$route.query.newJob === 'true') {
                this.selectedJobStatus === 'Writer'
                sidebarModule.state.lastSelectedJobStatus = 'Writer'
            } else {
                this.selectedJobStatus = 'Approved'
                sidebarModule.state.lastSelectedJobStatus = 'Approved'
            }
        } else if (this.currentUser?.role === 'Editor') {
            this.selectedJobStatus = 'Editor'
            sidebarModule.state.lastSelectedJobStatus = 'Editor'
        }

        // fetch exams
        if (!this.examDraft && this.job) {
            await examDraftsModule.actions.fetchExamDraft(this.job.examDraftId)
        }

        // fetch exam knowledge areas and scenarios
        if (this.examDraft) {
            this.knowledgeAreaDrafts = await kaDraftsModule.actions.fetchKADraftsByExamDraftId(this.examDraft.objectId)
            this.questionScenarioDrafts = await questionScenarioDraftsModule.actions
                .fetchQuestionScenarioDraftsByExamDraftId(this.examDraft.objectId)
        }

        // fetch questions for job
        if (!this.questionDrafts.length) {
            try {
                this.questionDrafts = (await questionDraftsModule.actions.fetchQuestionDrafts({
                    equalTo: {
                        job: objPointer((this.job as CMS.Cloud.JobWithMetrics).objectId)('Job'),
                    },
                })).results
            } catch (err) {
                this.error = 'Unable to fetch Job Question Drafts'
            }
        }

        // if has mock exam, fetch mock exam
        const mockExamDraftId = this.job.mockExamDraftId
        if (mockExamDraftId) {
            const mockExamDrafts = await mockExamDraftsModule.actions.fetchMockExamDrafts({
                examDraftId: this.job.examDraftId,
            })
            this.mockExamDraft = mockExamDrafts.find(med => med.objectId === mockExamDraftId) || null
        }

        this.isLoading = false
    }

    updateActiveFilters (activeFilters: string[]) {
        this.activeFilters = activeFilters
    }

    @Watch('selectedJobStatus')
    resetSelections () {
        this.selectedQuestionDrafts = []
    }
}
export default toNative(JobView)
</script>

<style lang="scss" scoped>
.job {
    padding-top: 43px;
    padding-bottom: 100px;
    flex: 1;
    max-width: 1132px;
    margin: auto;
    margin-top: 52px;

    &__content {
        min-width: 1132px;
        margin-right: 62px;
    }

    &__selected-status {
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: 0.3px;
        margin-top: 38px;
        margin-bottom: 6px;
    }
    
    &__job-subtext {
        font-size: 16px;
        line-height: 22px;
    }

    &__progress {
        display: flex;
        justify-content: space-between;
        max-width: 1132px;

        :deep(.job__progress-bar) {
            margin-right: 25px;
        }
    }

    :deep(.job-info) {
        min-height: 50px;
    }

    :deep(.list__results-container) {
        padding-right: 0;
        padding-top: 24px;
        padding-left: 0;
        min-height: 330px;
    }

    &__title-text {
        margin-top: 16px;
        margin-bottom: 9px;
        font-size: 40px;
    }
    
    &__subtitle-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 36px;
        letter-spacing: 0.2px;

        &--extra-distance {
            margin-top: 44px;
        }
    }

    &__subtitle-paragraph-text {
        width: 542px;
        color: $ash;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: normal;
        font-weight: 500;
        margin-bottom: 30px;
    }

    &__progress-bar {
        width: 40%;
    }



    &__center-buttons {
        position: absolute;
        left: calc(50% - 50px);
    }

    &__flagged-icon {
        color: $caramel;
        height: 20px;
    }

    &__suggestions-icons {
        height: 20px;
        color: $slate;

        .comment-icon {
            margin-right: 4px;
        }
    }

    &__empty-state-image {
        height: 92px;
        margin-bottom: 22px;
    }

    &__filter-type-dropdown-item-container {
        position: relative;
    }

    &__question-type-dropdown-item {
        display: flex;
    }

    &__question-type-dropdown-item-label {
        font-weight: 600;
        margin-right: 4px;
    }

    :deep(.uikit-select__item[data-value="Subject"]),
    :deep(.uikit-select__item[data-value="Supplemental Info"]),
    :deep(.uikit-select__item[data-value="Flagged"]) {
        margin-top: 31px;
    }

    :deep(.list__checkbox-label) {
        padding-left: 33px;
    }

    &__bulk-actions {
        display: flex;
        padding-left: 12px;
        margin-left: 15px;
    }

    &__create-scenario-bulk-action {
        padding: 4px 6px;
    }

    :deep(.list__result-count) {
        margin: 0;
    }

    &__toast {
        position: fixed;
        bottom: 73px;
    }

    &__filter-type-dropdown-section-title {
        position: absolute;
        top: -24px;
        color: $slate;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }

    &__right-side-buttons {
        position: absolute;
        right: 0;
        margin-right: 18px;
    }

    &__edit-job-button {
        margin-right: 8px;
    }

    &__options {
        float: right;
        margin-top: 18px;

        button {
            margin-left: 15px;
        }
    }

    &__info,
    &__goal {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: white;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid $cloudy;
        margin-bottom: $base;
    }

    &__info-item,
    &__goal-item {
        display: flex;
        flex-direction: column;
        margin: 10px;

        label {
            color: #666;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }

    &__info-item {
        min-width: 100px;
    }

    &__goal-item {
        min-width: 200px;
    }

    &__questions-to-create,
    &__questions-to-review,
    &__flagged-questions,
    &__submitted-questions,
    &__completed-questions {
        display: flex;
        flex-direction: column;
    }

    &__questions-to-create-actions,
    &__questions-to-review-actions {
        margin: 20px 0 0;

        label {
            font-size: 12px;
            display: block;
            margin-bottom: 6px;
        }
    }

    &__questions-to-create-list-container {
        border-top: 1px solid black;
    }

    &__error {
        color: red;
    }

    :deep(.list-controls__options) {
        padding-left: 0;
    }
}
</style>