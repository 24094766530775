<template>
    <div class="sidebar">
        <div class="sidebar__progress-section">
            <div 
                class="sidebar__writer-progress"
                :class="{ 'sidebar__writer-progress--selected': selectedJobStatus === 'Writer' }"
                @click="updateSelection('Writer')"
            >
                <div 
                    class="sidebar__selected-chip"
                    v-if="selectedJobStatus === 'Writer'">
                </div>
                <div class="sidebar__job-status">Writer</div>
                <div class="sidebar__numeral-progress">
                    {{ writerNumeralProgress }}
                </div>
                <div class="sidebar__percentage-progress">
                    {{ `${sidebarPayload.writerPercent}%` }}
                </div>
            </div>
            <div 
                class="sidebar__editor-progress"
                :class="{ 'sidebar__editor-progress--selected': selectedJobStatus === 'Editor' }"
                @click="updateSelection('Editor')"
            >
                <div 
                    class="sidebar__selected-chip"
                    v-if="selectedJobStatus === 'Editor'">
                </div>
                <div class="sidebar__job-status">Editor</div>
                <div class="sidebar__numeral-progress">
                    {{ sidebarPayload.numOfEditorQuestions}}
                </div>
            </div>
            <div 
                class="sidebar__completed-progress"
                :class="{ 'sidebar__completed-progress--selected': selectedJobStatus === 'Approved' }"
                @click="updateSelection('Approved')"
            >
                <div 
                    class="sidebar__selected-chip"
                    v-if="selectedJobStatus === 'Approved'">
                </div>
                <div class="sidebar__job-status">Approved</div>
                <div class="sidebar__numeral-progress">
                    {{ 
                        `${sidebarPayload.numOfTotalCompletedQuestions}/${sidebarPayload
                            .numOfTotalNewQuestionTemplates || sidebarPayload.numOfQuestionsToReview || '--'}` 
                    }}
                </div>
                <div class="sidebar__percentage-progress">
                    {{ `${completedPercent}%` }}
                </div>
            </div>
        </div>
        <div class="sidebar__job-deatails-section">
            <div class="sidebar__job-details-head">JOB DETAILS</div>
            <div class="sidebar__job-details-row">
                <div>Job Due</div>
                <div class="sidebar__data-block">{{ sidebarPayload.dueDate || '--' }}</div>
            </div>
            <div class="sidebar__job-details-row sidebar__lead-row">
                <div>Lead</div>
                <div class="sidebar__data-block">{{ sidebarPayload.leadName || '--' }}</div>
            </div>
            <div class="sidebar__job-details-row">
                <div>Writer</div>
                <div class="sidebar__data-block">{{ sidebarPayload.writerName || '--' }}</div>
            </div>
            <div class="sidebar__job-details-row">
                <div>Editor</div>
                <div class="sidebar__data-block">{{ sidebarPayload.editorName || '--' }}</div>
            </div>
            <div class="sidebar__job-details-row sidebar__question-types-row">
                <div>Question Types</div>
                <div class="sidebar__data-block">{{ sidebarPayload.allowedQuestionTypes }}</div>
            </div>
            <div class="sidebar__job-details-row sidebar__scenarios-row">
                <div>Scenarios</div>
                <div class="sidebar__data-block">{{ sidebarPayload.allowScenarios ? 'Yes' : 'No' }}</div>
            </div>
            <div class="sidebar__job-details-row">
                <div>Keyword Styling</div>
                <div class="sidebar__data-block">
                    {{ 
                        sidebarPayload.keywordStyling
                            ? capitalizeFirstLetters(sidebarPayload.keywordStyling)
                            : '--' 
                    }}
                </div>
            </div>
            <div class="sidebar__notes-and-links-section">
                <div class="sidebar__notes-and-links-head">Notes & Links</div>
                <div 
                    class="sidebar__notes-and-links"
                    v-html="sidebarPayload.notesAndLinks || '--'"
                ></div>
            </div>
            <div class="sidebar__job-details-row">
                <div>Plagiarism Score</div>
                <div class="data-block">{{ sidebarPayload.plagiarismScore || '--' }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, toNative } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import type { IJobSidebarPayload } from '@/store/jobs/types'

@Component({
    components: {
        PocketLink: UIKit.Link,
    },
})
class JobViewSidebar extends Vue {
    @Prop() readonly sidebarPayload!: IJobSidebarPayload
    @Prop() readonly selectedJobStatus!: 'Writer' | 'Editor' | 'Approved'

    selectedStatus: 'Writer' | 'Editor' | 'Approved' = 'Writer'

    get completedPercent () {
        if (this.sidebarPayload.jobType === 'Create Questions') {
            const pctCompletedCreatedQuestions = this.sidebarPayload.numOfTotalCompletedQuestions
             && this.sidebarPayload.numOfTotalNewQuestionTemplates
                && Math.floor(this.sidebarPayload.numOfTotalCompletedQuestions / this.sidebarPayload
                    .numOfTotalNewQuestionTemplates * 100)
            return pctCompletedCreatedQuestions
        } else {
            const pctCompletedReviewQuestions = this.sidebarPayload.numOfTotalCompletedQuestions
             && this.sidebarPayload.numOfQuestionsToReview
                && Math.floor(this.sidebarPayload.numOfTotalCompletedQuestions / this.sidebarPayload
                    .numOfQuestionsToReview * 100)
            return pctCompletedReviewQuestions
        }
    }

    get writerNumeralProgress () {
        const questionsToReview = this.sidebarPayload.numOfQuestionsToReview || 0
        const questionTemplates = this.sidebarPayload.numOfTotalNewQuestionTemplates || 0
        const questionsReviewedByWriter = this.sidebarPayload.numOfEditorQuestions
        const questionsApproved = this.sidebarPayload.numOfTotalCompletedQuestions || 0
        const questionsWritten = this.sidebarPayload.numOfQuestionDrafts
        if (questionTemplates) {
            return `${questionsWritten}/${questionTemplates}`
        } else if (questionsToReview) {
            return `${questionsReviewedByWriter + questionsApproved}/${questionsToReview}`
        } else {
            return '--'
        }
    }

    capitalizeFirstLetters (val: string) {
        return val.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')

    }

    mounted () {
        this.selectedStatus = this.selectedJobStatus
    }

    @Emit('jobStatusSelectionChanged')
    updateSelection (status: 'Writer' | 'Editor' | 'Approved') {
        this.selectedStatus = status
        return this.selectedStatus
    }

}
export default toNative(JobViewSidebar)
</script>

<style lang="scss" scoped>
.sidebar {
    width: 280px;
    height: 100%;
    position: fixed;
    left: 0;
    padding-top: 52px;
    padding-bottom: 67px;
    background-color: $moonlit-ocean;
    z-index: 99;
    color: $cloudy;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    &__selected-chip {
        position: absolute;
        height: 32px;
        width: 6px;
        border-radius: 4px 2px 2px 4px;
        background-color: $banana-bread;
    }

    &__progress-section {
        padding-top: 40px;
        border-bottom: 1px solid $ash;
        padding-bottom: 30px;
    }

    &__job-status {
        width: 98px;
        padding-left: 14px;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
    }

    &__numeral-progress {
        width: 104px;
        text-align: right;
        font-size: 14px;
        line-height: 32px;
    }

    &__percentage-progress {
        text-align: right;
        width: 38px;
        font-size: 14px;
        line-height: 32px;
        margin-left: 10px;
    }

    &__writer-progress {
        width: 260px;
        height: 32px;
        margin: auto;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        margin-bottom: 6px;
    }

    &__editor-progress {
        width: 260px;
        height: 32px;
        margin: auto;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        margin-bottom: 6px;
    }

    &__completed-progress {
        width: 260px;
        height: 32px;
        margin: auto;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
    }

    &__writer-progress:hover {
        background-color: rgba($banana-bread, 0.1);
    }

    &__editor-progress:hover {
        background-color: rgba($banana-bread, 0.1);
    }

    &__completed-progress:hover {
        background-color: rgba($banana-bread, 0.1);
    }

    &__writer-progress, 
    &__editor-progress, 
    &__completed-progress {
        cursor: default;

        &--selected {
            background-color: rgba($banana-bread, 0.4);

            .sidebar__job-status {
                color: $white;
            }

            .sidebar__numeral-progress, .sidebar__percentage-progress {
                color: rgba($white, 0.7);
            }

            &:hover {
                background-color: rgba($banana-bread, 0.4)!important;
                cursor: default;
            }
        }
    }

    &__job-details-head {
        font-size: 12px;
        color: $fog;
        font-weight: 600;
        letter-spacing: 1px;
        margin-top: 28px;
        margin-bottom: 24px;
    }

    &__job-deatails-section {
        padding-left: 24px;
        padding-right: 24px;
    }

    &__lead-row {
        margin-top: 15px;
    }

    &__question-types-row {
        margin-top: 14px;
    }

    &__notes-and-links-section {
        margin-bottom: 24px;
    }

    &__notes-and-links-head {
        margin-top: 34px;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 7px;
    }

    &__notes-and-links :deep(p) {
        margin: 0;
        color: $pearl;
        line-height: 22px;
        font-size: 14px;
    }

    &__notes-and-links :deep(a) {
            font-size: 14px;
            line-height: 19px;
            color: $banana-bread;
    }

    &__job-details-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &__data-block {
        color: $pearl;
        font-size: 14px;
        font-weight: 500;
        text-align: right;
        max-width: 124px;
    }

    &__added-note {
        color: $pearl;
    }
}
</style>
