import { objPointer, Parse } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import type {
    TCreateMockExamDrafts,
    TFetchMockExamDrafts,
    TUpdateMockExamDraft,
} from '@/store/mockExamDrafts/types'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import examDraftsModule from '@/store/examDrafts/module'

/**
 * Fetches all mock exam drafts for a specific exam draft and stores them in store
 *
 * @returns {Promise} resolves with MockExamJSON[]
 */
const fetchMockExamDrafts = async (
    { examDraftId, forceFetch }: Parameters<TFetchMockExamDrafts>[0]
): ReturnType<TFetchMockExamDrafts> => {
    // if examDrafts already in store and forceFetch is not true, return local examDrafts
    const storeExamDrafts = mockExamDraftsModule.state.mockExamDrafts
    if (storeExamDrafts[examDraftId] && !forceFetch) {
        return storeExamDrafts[examDraftId]
    }

    const examDraft = await new Parse.Query<CMS.Class.ExamDraft>('ExamDraft')
        .include('mockExamDrafts')
        .get(examDraftId)
    const mockExamDrafts = (examDraft.get('mockExamDrafts') as CMS.Class.MockExamDraft[] | undefined || [])
        .map(me => me.toJSON())

    mockExamDraftsModule.state.mockExamDrafts = {
        ...mockExamDraftsModule.state.mockExamDrafts,
        [examDraftId]: mockExamDrafts,
    }

    return mockExamDrafts
}

/**
 * Fetches all mock exam drafts
 *
 * @returns {Promise} resolves with MockExamJSON[]
 */

const fetchAllMockExamDrafts = async (): ReturnType<TFetchMockExamDrafts> => {
    const allMockExamDrafts = (await new Parse.Query<CMS.Class.MockExamDraft>('MockExamDraft')
        .findAll())
        .map(med => med.toJSON())

    return allMockExamDrafts
}

/**
 * Create a mock exam draft
 */
const createMockExamDrafts = async (
    { examDraftId, payload }: Parameters<TCreateMockExamDrafts>[0]
): ReturnType<TCreateMockExamDrafts> => {
    const examDraft = examDraftsModule.getters.getExamDraft(examDraftId)
    
    if (!examDraft) {
        throw new Error('Unable to create mock exam draft: No exam draft found')
    }

    const mockExamDrafts = await Promise.all(payload.map(mockExam => (new Parse.Object('MockExamDraft', {
        name: mockExam.name,
        description: mockExam.description,
        durationSeconds: mockExam.durationSeconds,
        enabled: mockExam.enabled,
        questionSerials: mockExam.questionSerials,
        mockExamId: mockExam.mockExamId,
    })).save()))

    // Double check that mockExamDraft values are pointers
    const mappedMockExamDrafts = examDraft.mockExamDrafts?.map(mockExamDraft =>
        objPointer(mockExamDraft.objectId)('MockExamDraft')
    )

    await examDraftsModule.actions.updateExamDraft({
        examDraftId,
        params: {
            mockExamDrafts: [
                ...mappedMockExamDrafts || [],
                ...mockExamDrafts.map(med => objPointer(med.id)('MockExamDraft')),
            ],
        },
    })

    return mockExamDrafts.map(med => med.toJSON())
}

/**
 * Update a mock exam draft
 */
const updateMockExamDraft = async (
    mockExamDraftPayload: Parameters<TUpdateMockExamDraft>[0]
): ReturnType<TUpdateMockExamDraft> => {
    if (!mockExamDraftPayload.objectId) {
        throw new Error('MockExamDraft objectId is required')
    }

    const mockExamDraft = await new Parse.Query<CMS.Class.MockExamDraft>('MockExamDraft')
        .get(mockExamDraftPayload.objectId)
    
    mockExamDraft.set({
        name: mockExamDraftPayload.name,
        description: mockExamDraftPayload.description,
        durationSeconds: mockExamDraftPayload.durationSeconds,
        enabled: mockExamDraftPayload.enabled,
        acronym: mockExamDraftPayload.acronym,
    })

    // only set if question serials are passed
    if (mockExamDraftPayload.questionSerials) {
        mockExamDraft.set('questionSerials', mockExamDraftPayload.questionSerials)
    }
    await mockExamDraft.save()

    return mockExamDraft.toJSON()
}

export default {
    fetchMockExamDrafts,
    fetchAllMockExamDrafts,
    createMockExamDrafts,
    updateMockExamDraft,
}
