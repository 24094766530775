import type { 
    TSendAdminFlagAlertEmail,
    TSendAdminJobCompleteEmail,
    TSendWriterJobCompleteEmail,
    TFetchJobMetrics,
    TCompleteJob,
    TFetchJobs,
    TFetchJob,
    TCreateJob,
    TUpdateJob,
    TDeleteJob,
    TCheckJobPlagiarism,
} from './types'
import { Parse, sessionToken, objPointer } from '@/store/ParseUtils'
import type { CMS } from '@pocketprep/types'
import jobsModule from '@/store/jobs/module'
import questionDraftsModule from '@/store/questionDrafts/module'

/**
 * Convert Parse.Object into IJob typed object for store
 *
 * @param {Parse.Object} job - job object returned from Parse including
 *                           writer.firstName, writer.lastName, editor.firstName,
 *                           and editor.lastName
 *
 * @returns {IJob} formatted IJob typed object for store
 */
const parseJobToStoreJob = (job: CMS.Class.Job): CMS.Cloud.JobWithMetrics => {
    const {
            name,
            type,
            jobFunction,
            references,
            dueDate: parseDueDate,
            objectId,
            keywordStyling,
            notesAndLinks,
            questionTemplates,
            allowedQuestionTypes,
            isCompleted,
            examDraft,
            mockExamDraft,
            allowScenarios,
            runPlagiarismCheck,
            supplementalInfoLabels,
        } = job.toJSON(),
        jobEditor = job.get('editor'),
        jobWriter = job.get('writer'),
        jobLead = job.get('lead'),
        editorId = jobEditor ? jobEditor.id : '',
        writerId = jobWriter ? jobWriter.id : '',
        leadId = jobLead ? jobLead.id : '',
        mockExamDraftId = mockExamDraft?.objectId

    const dueDate: string | undefined = parseDueDate && parseDueDate.iso

    return { 
        objectId, 
        name, 
        type,
        jobFunction,
        references, 
        dueDate: dueDate || '', 
        editorId, 
        writerId,
        leadId,
        keywordStyling,
        notesAndLinks,
        questionTemplates, 
        allowedQuestionTypes, 
        isCompleted,
        examDraftId: examDraft ? examDraft.objectId : '',
        mockExamDraftId,
        allowScenarios,
        runPlagiarismCheck,
        supplementalInfoLabels,
    }
}

const updateStoreJob = (jobUpdate: CMS.Cloud.JobWithMetrics) => {
    const jobsState = jobsModule.state
    const jobIndex = jobsState.jobs.findIndex(job => job.objectId === jobUpdate.objectId)

    // replace existing job if found
    if (jobIndex !== -1) {
        jobsState.jobs[jobIndex] = jobUpdate
    } else {
        // otherwise add job onto store array
        jobsState.jobs.push(jobUpdate)
    }
}

/**
 * Email admin users if 5 questions have been flagged on job in last 24 hours
 */
const sendAdminFlagAlertEmail = async (
    { jobName, jobId, userId }: Parameters<TSendAdminFlagAlertEmail>[0]
): ReturnType<TSendAdminFlagAlertEmail> => {
    const yesterday = new Date()
    yesterday.setDate(new Date().getDate() - 1)

    const flagActivities = await new Parse.Query('Activity')
        .equalTo('action', 'flag')
        .equalTo('job', { __type: 'Pointer', className: 'Job', objectId: jobId })
        .equalTo('user', { __type: 'Pointer', className: '_User', objectId: userId })
        .greaterThan('createdAt', { __type: 'Date', iso: yesterday.toISOString() })
        .count()

    if (flagActivities === 5) {
        const subscribedAdminUsers = await new Parse.Query('_User')
            .equalTo('subscribedAdminEmails', true)
            .findAll({ batchSize: 2000 })

        await Promise.all(subscribedAdminUsers.map(async user =>
            await Parse.Cloud.run<CMS.Cloud.sendAdminEditorFlaggedEmail>('sendAdminEditorFlaggedEmail', {
                email: user.get('username'),
                name: user.get('firstName') + ' ' + user.get('lastName'),
                jobName,
                path: '/jobs/' + jobId,
            })
        ))
        
        return true
    }

    return false
}

/**
 * Email admin user when a job is completed by the editor
 */
const sendAdminJobCompleteEmail = async (
    jobName: Parameters<TSendAdminJobCompleteEmail>[0]): ReturnType<TSendAdminJobCompleteEmail> => {
    const subscribedAdminUsers = await new Parse.Query('_User')
        .equalTo('subscribedAdminEmails', true)
        .findAll({ batchSize: 2000 })

    await Promise.all(subscribedAdminUsers.map(async user =>
        await Parse.Cloud.run<CMS.Cloud.sendAdminEditorCompleteEmail>('sendAdminEditorCompleteEmail', {
            email: user.get('username'),
            name: user.get('firstName') + ' ' + user.get('lastName'),
            jobName,
        })
    ))

    return true
}

/**
 * Email writer when a job is completed by the editor
 */
const sendWriterJobCompleteEmail = async (
    jobId: Parameters<TSendWriterJobCompleteEmail>[0]): ReturnType<TSendWriterJobCompleteEmail> => {
    const job = jobsModule.getters.getJob(jobId)
        || await fetchJob(jobId)

    if (job?.writerId) {
        const writer = await new Parse.Query('_User').equalTo('objectId', job.writerId).first()

        if (writer) {
            // send email to new user
            await Parse.Cloud.run<CMS.Cloud.sendWriterJobCompleteEmail>('sendWriterJobCompleteEmail', {
                email: writer.get('username'),
                name: writer.get('firstName') + ' ' + writer.get('lastName'),
                jobName: job.name,
            })
            
            return true
        }
    }

    return false
}

/**
 * Fetch stats for all jobs
 * 
 * @param {IJob[]} jobs - array of jobs that you want to calculate metrics for
 *
 * @returns {Promise} resolves with IJob[] when query completes
 */
const fetchJobsMetrics = async (jobs: Parameters<TFetchJobMetrics>[0]): ReturnType<TFetchJobMetrics> => {
    return Parse.Cloud.run<CMS.Cloud.calculateJobsMetrics>('calculateJobsMetrics', { jobs })
}

/**
 * Mark job as completed
 * 
 * @param {string} jobId - ID of job to mark as completed
 * 
 * @returns {Promise} resolves with IJob when query completes
 */
const completeJob = async (jobId: Parameters<TCompleteJob>[0]): ReturnType<TCompleteJob> => {
    // calculate job question templates based on actually created questions
    const questionTemplates = (await questionDraftsModule.actions.fetchQuestionDrafts({
        equalTo: {
            job: objPointer(jobId)('Job'),
        },
    })).results
        .filter((questionDraft) => !questionDraft.examDataId)
        .reduce((acc, val) => {
            const knowledgeAreaDraftId = val.knowledgeAreaDraft && val.knowledgeAreaDraft.objectId
            const existingTemplate = acc.find(template => template.knowledgeAreaDraftId === knowledgeAreaDraftId)

            if (existingTemplate) {
                existingTemplate.count++
            } else if (knowledgeAreaDraftId) {
                acc = [
                    ...acc,
                    {
                        knowledgeAreaDraftId,
                        count: 1,
                    },
                ]
            }

            return acc
        }, [] as { knowledgeAreaDraftId: string; count: number }[])

    const updatedJob = new Parse.Object('Job')
    updatedJob.set({
        objectId: jobId,
        isCompleted: true,
        questionTemplates,
    })
    const { id: savedJobId } = await updatedJob.save()

    // refresh job data and metrics in store
    const job = await fetchJob(savedJobId)

    if (!job) {
        throw new Error('completeJob: Unable to fetch updated job')
    }

    // update questions
    const questionQuery = new Parse.Query<CMS.Class.QuestionDraft>('QuestionDraft')
    questionQuery.equalTo('job', objPointer(job.objectId)('Job'))
    
    const questions = await questionQuery.findAll({
        ...sessionToken(),
        batchSize: 10000,
    })
    questions.forEach(question => {
        question.set({
            jobStatus: 'Completed',
            draftStatus: 'inactive',
        })
    })
    await Parse.Object.saveAll(questions, {
        ...sessionToken(),
    })

    // refresh question draft IDs in store
    await questionDraftsModule.actions.fetchQuestionDraftExamIds()

    // email editor about new job
    if (job.editorId) {
        const editor = await new Parse.Query('_User').equalTo('objectId', job.editorId).first()

        if (editor) {
            // send email to new user
            await Parse.Cloud.run<CMS.Cloud.sendEditorJobCompleteEmail>('sendEditorJobCompleteEmail', {
                email: editor.get('username'),
                name: editor.get('firstName') + ' ' + editor.get('lastName'),
                jobName: job.name,
            })
        }
    }

    return job
}

/**
 * Fetch all jobs
 *
 * @returns {Promise} resolves with IJob[] when query completes
 */
const fetchJobs = async (): ReturnType<TFetchJobs> => {
    const jobQuery = new Parse.Query<Parse.Object>('Job')
    jobQuery.include([
        'writer.firstName',
        'writer.lastName',
        'editor.firstName',
        'editor.lastName',
    ])

    const jobs = (await jobQuery.findAll({
        ...sessionToken(),
        batchSize: 2000,
    })) as CMS.Class.Job[]
    const jobsMapped: CMS.Cloud.JobWithMetrics[] = 
            await Promise.all(jobs.map(async job => parseJobToStoreJob(job)))
    const jobsMetrics = await fetchJobsMetrics(jobsMapped)
    const jobsWithMetrics: CMS.Cloud.JobWithMetrics[] = jobsMapped.map(job => ({
        ...job,
        ...(jobsMetrics.find(jobMetric => jobMetric.objectId === job.objectId)),
    }))

    jobsModule.state.jobs = jobsWithMetrics

    return jobsMapped
}

/**
 * Fetch single job by id and commit to store
 *
 * @param {string} jobId - ID of job to fetch
 *
 * @returns {Promise} resolves to IJob of fetched job object or undefined if no job found
 */
const fetchJob = async (jobId: Parameters<TFetchJob>[0]): ReturnType<TFetchJob> => {
    const foundJob = await new Parse.Query<Parse.Object>('Job')
        .equalTo('objectId', jobId)
        .include([
            'writer.firstName',
            'writer.lastName',
            'editor.firstName',
            'editor.lastName',
            'lead.firstName',
            'lead.lastName',
        ])
        .first() as CMS.Class.Job

    if (!foundJob) {
        return undefined
    }

    const job = parseJobToStoreJob(foundJob)
    const jobsMetrics = await fetchJobsMetrics([ job ])
    const jobWithMetrics: CMS.Cloud.JobWithMetrics = {
        ...job,
        ...jobsMetrics[0],
    }

    updateStoreJob(jobWithMetrics)

    return jobWithMetrics
}

/**
 * Create new job
 *
 * @param {string} name - name of the job
 * @param {string} [editorId] - ID of editor for job
 * @param {string} [writerId] - ID of writer for job
 * @param {array} [references] - array of strings of references
 * @param {CMS.Class.IAllowedQuestionTypes} [allowedQuestionTypes] - allowed question types
 * @param {date} [dueDate] - date job is due
 * @param {IQuestionTemplate[]} [questionTemplates] - array of IKnowledgeAreaDraft typed objects
 * @param {IJobQuestion[]} [questions] - array of IJobQuestion typed objects
 * @param {string} [examId] - ID of exam for job
 *
 * @returns {Promise} resolves to IJob of new job object
 */
const createJob = async (
    {
        name,
        type,
        jobFunction,
        editorId,
        writerId,
        leadId,
        dueDate,
        keywordStyling,
        notesAndLinks,
        references,
        questionTemplates,
        allowedQuestionTypes,
        examDraft,
        mockExamDraft,
        allowScenarios,
        runPlagiarismCheck,
        supplementalInfoLabels,
    }: Parameters<TCreateJob>[0]): ReturnType<TCreateJob> => {
    
    // save new job to db
    const newJob = new Parse.Object('Job')
    newJob.set({
        name,
        type,
        jobFunction,
        editor: editorId && objPointer(editorId)('_User'),
        writer: writerId && objPointer(writerId)('_User'),
        lead: leadId && objPointer(leadId)('_User'),
        keywordStyling,
        notesAndLinks,
        references,
        dueDate,
        questionTemplates,
        allowedQuestionTypes,
        examDraft,
        isCompleted: false,
        mockExamDraft,
        allowScenarios,
        runPlagiarismCheck,
        supplementalInfoLabels,
    })
    await newJob.save(null, sessionToken())

    // get exam metadata ID
    if (!examDraft) {
        throw new Error('Unable to create new job')
    }

    // email editor about new job
    if (editorId) {
        const editor = await new Parse.Query('_User').equalTo('objectId', editorId).first()

        if (editor) {
            // send email to new user
            await Parse.Cloud.run<CMS.Cloud.sendNewJobEmail>('sendNewJobEmail', {
                email: editor.get('username'),
                name: editor.get('firstName') + ' ' + editor.get('lastName'),
                jobName: name,
                path: '/jobs/' + newJob.id,
                role: 'an editor',
            })
        }
    }

    // email writer about new job
    if (writerId) {
        const writer = await new Parse.Query('_User').equalTo('objectId', writerId).first()

        if (writer) {
            // send email to new user
            await Parse.Cloud.run<CMS.Cloud.sendNewJobEmail>('sendNewJobEmail', {
                email: writer.get('username'),
                name: writer.get('firstName') + ' ' + writer.get('lastName'),
                jobName: name,
                path: '/jobs/' + newJob.id,
                role: 'a writer',
            })
        }
    }

    // fetch new job and store it
    const job = await fetchJob(newJob.id)

    if (!job) {
        throw new Error('Unable to create job')
    }

    return job
}

/**
     * Request a plagiarism check on all questions in a job
     *
     * @param {string} jobId - ID of the job draft to check for plagiarism
     * @returns
     */
const checkJobPlagiarism = async (jobId: Parameters<TCheckJobPlagiarism>[0]): ReturnType<TCheckJobPlagiarism> => {
    return await Parse.Cloud.run('checkJobForPlagiarism', { jobId }) && true
}

/**
 * Update job and commit updated job to store
 *
 * @param {string} name - name of the job
 * @param {string} [editorId] - ID of editor for job
 * @param {string} [writerId] - ID of writer for job
 * @param {array} [references] - array of strings of references
 * @param {CMS.Class.IAllowedQuestionTypes} [allowedQuestionTypes] - allowed question types
 * @param {date} [dueDate] - date job is due
 * @param {IQuestionTemplate[]} [questionTemplates] - array of IKnowledgeAreaDraft typed objects
 * @param {IJobQuestion[]} [questions] - array of IJobQuestion typed objects
 *
 * @returns {Promise} resolves to IJob of updated job or 
 * undefined if job id does not match an job in the store
 */
const updateJob = async (
    {
        objectId,
        name,
        jobFunction,
        editorId,
        writerId,
        leadId,
        keywordStyling,
        notesAndLinks,
        dueDate,
        references,
        allowedQuestionTypes,
        questionTemplates,
        allowScenarios,
        runPlagiarismCheck,
        supplementalInfoLabels,
    }: Parameters<TUpdateJob>[0]
): ReturnType<TUpdateJob> => {
    if (!objectId) {
        throw new Error('Unable to update job without objectId')
    }

    // update job
    const job = new Parse.Object('Job', {
        objectId,
        name,
        jobFunction,
        editor: (editorId && objPointer(editorId)('_User')) || undefined,
        writer: (writerId && objPointer(writerId)('_User')) || undefined,
        lead: (leadId && objPointer(leadId)('_User')) || undefined,
        keywordStyling,
        notesAndLinks,
        references,
        dueDate,
        allowedQuestionTypes,
        questionTemplates,
        allowScenarios,
        runPlagiarismCheck,
        supplementalInfoLabels,
    })
    if (!editorId) {
        job.unset('editor')
    }
    if (!writerId) {
        job.unset('writer')
    }
    if (!leadId) {
        job.unset('lead')
    }
    await job.save(null, sessionToken())

    // fetch new job and store it
    const updatedJob = await fetchJob(objectId)

    if (!updatedJob) {
        throw new Error('Failed to update job')
    }

    return updatedJob
}

/**
 * Delete job and remove from store
 *
 * @param {string} jobId - ID of job to update
 *
 * @returns {Promise} resolves to true if successful delete or false if failed
 */
const deleteJob = async (
    jobId: Parameters<TDeleteJob>[0]): ReturnType<TDeleteJob> => {
    const job = new Parse.Object('Job')
    job.set('objectId', jobId)

    try {
        const destroyedJob = await job.destroy(sessionToken())

        // fetch questions
        const questionQuery = new Parse.Query<CMS.Class.QuestionDraft>('QuestionDraft')
        questionQuery.equalTo('job', objPointer(destroyedJob.id)('Job'))
        
        const questions = await questionQuery.findAll({
            ...sessionToken(),
            batchSize: 2000,
        })

        const newQuestions = questions.filter(q => !q.get('examDataId'))
        const deletedSerialSet = new Set(newQuestions.map(q => q.get('serial') as string))

        // fetch mock exam draft if job was associated with one and job had new questions in it
        const jobMockExamDraft = destroyedJob.get('mockExamDraft')
        if (jobMockExamDraft && newQuestions.length) {
            const mockExamDraftId = 'id' in jobMockExamDraft ? jobMockExamDraft.id : jobMockExamDraft.objectId
            const mockExamDraft = await new Parse.Query<CMS.Class.MockExamDraft>('MockExamDraft')
                .get(mockExamDraftId)
            mockExamDraft.removeAll('questionSerials', [ ...deletedSerialSet ])
            await mockExamDraft.save(null, sessionToken())
        }

        const scenarioDraftIds = questions.map(q => {
            const scenarioDraft = q.get('questionScenarioDraft')
            return scenarioDraft && ('id' in scenarioDraft ? scenarioDraft.id : scenarioDraft.objectId)
        }).filter((scenarioDraftId): scenarioDraftId is string => !!scenarioDraftId)

        const scenarioDrafts = await new Parse.Query<CMS.Class.QuestionScenarioDraft>('QuestionScenarioDraft')
            .containedIn('objectId', scenarioDraftIds)
            .findAll({ ...sessionToken(), batchSize: 2000 })

        // Only delete scenario drafts if brand new and all their questions are getting deleted
        const scenarioDraftsToDelete = scenarioDrafts.filter(scenarioDraft => {
            const allQuestionsDeleted = scenarioDraft.get('questionDrafts').every(qd => deletedSerialSet.has(qd.serial))

            return !scenarioDraft.get('questionScenarioId') && allQuestionsDeleted
        })

        // destroy questions
        await Parse.Object.destroyAll(questions)

        // destroy scenarios
        await Parse.Object.destroyAll(scenarioDraftsToDelete)

        // update store
        const filteredJobs = jobsModule.state.jobs.filter(item => item.objectId !== destroyedJob.id)
        jobsModule.state.jobs = filteredJobs

        // refresh question draft IDs in store
        await questionDraftsModule.actions.fetchQuestionDraftExamIds()

        return true
    } catch (e) {
        return false
    }
}

export default {
    sendAdminFlagAlertEmail,
    sendAdminJobCompleteEmail,
    sendWriterJobCompleteEmail,
    fetchJobsMetrics,
    completeJob,
    fetchJobs,
    fetchJob,
    createJob,
    checkJobPlagiarism,
    updateJob,
    deleteJob,
}
