<template>
    <div class="select-field"
         @mouseenter="mouseOverSelect"
         @mouseleave="hover = false"
    >
        <label
            v-if="label"
            class="select-field__label"
            :class="{
                'select-field__label--hover': hover,
                'select-field__label--focus': focus,
            }"
        >
            {{ label }}
        </label>
        <div
            class="select-field__select-container"
            :tabindex="0"
            :aria-expanded="showDropdown ? 'true' : 'false'"
            @focus="focusSelect"
            @click="openDropdown"
            @blur="focus = false; showDropdown = false;"
        >
            <div 
                class="select-field__value"
                :class="{
                    'select-field__value--placeholder': !selectValue && selectValue !== false,
                    'select-field__value--hover': hover,
                    'select-field__value--focus': focus,
                    'select-field__value--disabled': disabled,
                }"
            >
                <slot name="selectValue" :selectValue="selectValue">
                    {{ selectValue || selectValue === false ? getValueLabel(selectValue) : placeholder }}
                </slot>
            </div>
            <Icon
                type="accordionArrow"
                class="select-field__arrow"
                :class="{
                    'select-field__arrow--hover': hover,
                    'select-field__arrow--show-dropdown': showDropdown,
                }"
            />
        </div>
        <div
            v-show="showDropdown"
            class="select-field__dropdown"
        >
            <ul class="select-field__list">
                <slot name="options" :selectItem="selectItem">
                    <template v-if="data && data.length">
                        <li
                            v-for="item in data"
                            :key="JSON.stringify(item)"
                            :data-value="item.value"
                            class="select-field__item"
                            tabindex="0"
                            @click="selectItem(item)"
                            @mousedown.prevent
                        >
                            <slot name="dropdownItem" :item="item">
                                {{ stripHtmlTags(item.label) }}
                            </slot>
                        </li>
                    </template>
                    <template v-if="stringData && stringData.length">
                        <li
                            v-for="item in stringData"
                            :key="item"
                            :data-value="item || undefined"
                            class="select-field__item"
                            tabindex="0"
                            @click="selectItem(item)"
                            @mousedown.prevent
                        >
                            <slot name="dropdownItem" :item="item">
                                {{ stripHtmlTags(item) }}
                            </slot>
                        </li>
                    </template>
                </slot>
            </ul>
            <div v-if="showFooter" class="select-field__dropdown-footer">
                <slot name="dropdownFooter"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from 'vue-facing-decorator'
import UIKit from '@pocketprep/ui-kit'
import { stripHtmlTags } from '@/utils'

interface IItem {
    value: string | null | boolean
    label: string
    disabled?: boolean
    [key: string]: string | null | boolean | undefined
}

@Component({
    components: {
        Icon: UIKit.Icon,
    },
})
export default class SelectV2 extends Vue {
    @Prop() label?: string
    @Prop() data!: IItem[]
    @Prop() stringData!: string[]
    @Prop({ default: false }) disabled?: boolean
    @Prop({ default: false }) error?: boolean
    @Prop({ default: '' }) modelValue!: string | null | boolean | IItem
    @Prop({ default: '' }) autocomplete!: string
    @Prop({ default: '' }) placeholder!: string
    @Prop({ default: false }) useObjectModel?: boolean
    @Prop({ default: false }) showFooter!: boolean

    selectValue: string | null | boolean | IItem = ''
    showDropdown = false
    focus = false
    hover = false

    mounted () {
        this.selectValue = this.modelValue
    }

    stripHtmlTags = stripHtmlTags

    getValueLabel (value: string | null | boolean | IItem) {
        if (this.useObjectModel && typeof value === 'object') {
            return value?.label
        }
        if (this.data) {
            return this.data.find(item => item.value === value)?.label
        } else {
            return value
        }
    }

    openDropdown () {
        if (!this.disabled) {
            this.showDropdown = !this.showDropdown
        }
    }

    selectItem (item: IItem | string) {
        if (item) {
            this.showDropdown = false
        }
        if (typeof item === 'object' && !this.useObjectModel) {
            this.selectValue = item.value
        } else if (typeof item === 'object' && this.useObjectModel) {
            this.selectValue = item
        } else {
            this.selectValue = item
        }
        this.valueChange()
    }

    mouseOverSelect () {
        this.hover = this.disabled ? false : true
    }

    focusSelect () {
        this.focus = this.disabled ? false : true
    }

    @Watch('modelValue')
    onValueChange (val: string) {
        this.selectValue = val
    }

    @Emit('update:modelValue')
    valueChange () {
        return this.selectValue
    }
}
</script>

<style lang="scss" scoped>

.select-field {
    position: relative;

    &__label {
        font-size: 13px;
        line-height: 18px;
        font-weight: 600;
        margin: 0 0 4px 12px;
        color: $pickled-bluewood;
        display: block;

        &--hover {
            color: $slate-03;

            &--dark {
                color: $fog;
            }
        }

        &--focus {
            color: $brand-blue;

            &--dark {
                color: $banana-bread;
            }
        }
    }

    &__value {
        background-color: $white;
        border: 1px solid rgba($pewter, 0.85);
        color: $brand-black;
        border-radius: 3px;
        caret-color: $brand-blue;
        padding: 9px 11px;
        font-size: 15px;
        line-height: 18px;
        width: 100%;
        height: 36px;
        box-sizing: border-box;
        cursor: default;
        position: relative;
        z-index: 1;
        outline: none;
        appearance: none;
        user-select: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 32px;

        &--placeholder,
        &::placeholder {
            color: $pewter;
        }

        &--hover {
            border: 1px solid $baby-blue;
        }

        &--focus {
            border: 1px solid $brand-blue;
        }

        &--disabled {
            background: $gray-background;
            border: 1px solid rgba($pewter, 0.5);
            color: $slate-03;
        }
    }

    &__dropdown {
        box-shadow: 0 4px 12px 0 rgba($brand-black, 0.1);
        margin: 0;
        list-style: none;
        position: absolute;
        left: 0;
        width: 100%;
        max-height: 187px;
        overflow: auto;
        background: $white;
        z-index: 2;
        border-radius: 0 0 6px 6px;
    }

    &__dropdown-footer {
        border-top: 1px solid $fog;
    }

    &__list {
        padding: 10px 0;
    }
    
    &__item {
        padding: 5px 11px;
        font-size: 15px;
        line-height: 20px;
        cursor: default;
        user-select: none;

        &:hover,
        &:focus {
            background: $gray-background;
            outline: none;
        }
    }

    &__select-container {
        outline: none;
        position: relative;
    }

    &__arrow {
        position: absolute;
        right: 13px;
        top: 14px;
        color: $steel;
        width: 12px;
        height: 8px;
        z-index: 1;

        &--hover {
            color: $brand-blue;
        }

        &--focus {
            color: $brand-blue;

            &--dark {
                color: $banana-bread;
            }
        }

        &--show-dropdown {
            color: $brand-blue;
            transform: rotate(180deg);
        }
    }
}
</style>
