<template>
    <div id="app">
        <RouterView />
        <Toast
            v-if="toast"
            @close="clearToast"
        >
            {{ toast.title }}
            <template v-if="toast.subtext" #subtext>
                {{ toast.subtext }}
            </template>
        </Toast>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { toastModule } from '@/store/toast/module'
import UIKit from '@pocketprep/ui-kit'

// Toasts
const Toast = UIKit.Toast
const toast = computed(() => toastModule.state.toast)
const clearToast = () => {
    toastModule.actions.clearToast()
}

</script>
