import { commentsModule } from '@/store/comments/module'
import { getInitialCommentsState } from '@/store/comments/state'
import examDraftsModule from '@/store/examDrafts/module'
import { getInitialExamDraftsState } from '@/store/examDrafts/state'
import examsModule from '@/store/exams/module'
import { getInitialExamsState } from '@/store/exams/state'
import jobsModule from '@/store/jobs/module'
import { getInitialJobsState } from '@/store/jobs/state'
import listsModule from '@/store/lists/module'
import { getInitialListsState } from '@/store/lists/state'
import mockExamDraftsModule from '@/store/mockExamDrafts/module'
import { getInitialMockExamDraftsState } from '@/store/mockExamDrafts/state'
import navigationModule from '@/store/navigation/module'
import { getInitialNavigationState } from '@/store/navigation/state'
import questionDraftsModule from '@/store/questionDrafts/module'
import { getInitialQuestionDraftsState } from '@/store/questionDrafts/state'
import questionsModule from '@/store/questions/module'
import { getInitialQuestionsState } from '@/store/questions/state'
import { usersModule } from '@/store/users/module'
import { getInitialUsersState } from '@/store/users/state'
import sidebarModule from './sidebar/module'
import { getInitialSidebarState } from './sidebar/state'
import { toastModule } from '@/store/toast/module'
import { getInitialToastState } from '@/store/toast/state'

const mapStateProperties = <ModuleState>(
    moduleState: ModuleState,
    newState: ModuleState
) => {
    for (const stateKey in newState) {
        moduleState[stateKey] = newState[stateKey]
    }
}

export const resetState = () => {
    mapStateProperties(commentsModule.state, getInitialCommentsState())
    mapStateProperties(examDraftsModule.state, getInitialExamDraftsState())
    mapStateProperties(examsModule.state, getInitialExamsState())
    mapStateProperties(jobsModule.state, getInitialJobsState())
    mapStateProperties(listsModule.state, getInitialListsState())
    mapStateProperties(mockExamDraftsModule.state, getInitialMockExamDraftsState())
    mapStateProperties(navigationModule.state, getInitialNavigationState())
    mapStateProperties(questionDraftsModule.state, getInitialQuestionDraftsState())
    mapStateProperties(questionsModule.state, getInitialQuestionsState())
    mapStateProperties(usersModule.state, getInitialUsersState())
    mapStateProperties(sidebarModule.state, getInitialSidebarState())
    mapStateProperties(toastModule.state, getInitialToastState())
}