import navigationModule from '@/store/navigation/module'
import type { RouteParams } from 'vue-router'

const updateMenuVisible = (menuVisible: boolean) => {
    navigationModule.state.menuVisible = menuVisible
    localStorage.setItem('menuVisible', String(menuVisible))
}

const addRouteToHistory = (route: {name: string; params: RouteParams}) => {
    const navHistory = navigationModule.state.navigationHistory
    navHistory.push(route)

    // for now we don't need to save more than 5 last routes
    if (navigationModule.state.navigationHistory.length > 5) {
        navHistory.shift()
    }
}

export default {
    updateMenuVisible,
    addRouteToHistory,
}
