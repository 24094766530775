import { Parse } from '@/store/ParseUtils'
import type { Study } from '@pocketprep/types'

const fetchQuestionScenarios = async ({ examMetadataId }: {
    examMetadataId: string
}) => {
    const questionScenarios = await Parse.Cloud.run<(params: {
        examMetadataId: string
    }) => Study.Class.QuestionScenarioJSON[]>('fetchQuestionScenarios', { examMetadataId })
    return questionScenarios
}

export default {
    fetchQuestionScenarios,
}
