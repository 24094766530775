<template>
    <div
        class="outline-button"
        :class="{
            'outline-button--disabled': disabled,
        }"
    >
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component
export default class OutlineButton extends Vue {
    @Prop disabled?: boolean
}
</script>

<style lang="scss" scoped>
.outline-button {
    width: fit-content;
    padding: 10px 18px 9px 18px;
    border: 1px solid $brand-blue;
    border-radius: 4px;
    background-color: $floral-white;
    color: $brand-blue;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    box-sizing: border-box;
    cursor: default;

    &--disabled {
        background-color: $floral-white;
        color: rgba($brand-blue, 0.4);
        border-color: rgba($brand-blue, 0.4);
    }

    :not(&--disabled) {
        &:hover {
            cursor: pointer;
            background-color: $brand-blue;
            color: $white;
        }
    }
}
</style>
